import { Enum_Question_Type, toValidNumber } from '@cyren/common-lib';
import { memo } from 'react';
import useInAdminDashboard from '../../admin/hooks/use-in-admin-dashboard';
import { TQuestion } from '../../graphql-enhanced';
import QsAbdLocation from '../../report/components/QsAbdLocation';
import QsFileInput from '../../report/components/QsFileInput';
import QsInputRange from '../../report/components/QsInputRange';
import { getAutocompletePropForQs } from '../../utils/models/question';
import { QuestionProps } from '../pa-prop-types';
import QsBloodPressure from '../question/typed-qs/QsBloodPressure';
import QsCollapsible from '../question/typed-qs/QsCollapsible';
import QsFrequencyInput from '../question/typed-qs/QsFrequencyInput';
import QsInputBodySelect from '../question/typed-qs/QsInputBodySelect';
import QsInputDate from '../question/typed-qs/QsInputDate';
import QsInputDateDob from '../question/typed-qs/QsInputDateDob';
import QsInputDatetime from '../question/typed-qs/QsInputDatetime';
import QsInputNumberUnit from '../question/typed-qs/QsInputNumberUnit';
import QsInputOnset from '../question/typed-qs/QsInputOnset';
import QsInputOnsetV2 from '../question/typed-qs/QsInputOnsetV2';
import QsInputRelativeOnset from '../question/typed-qs/QsInputRelativeOnset';
import QsInputSelect from '../question/typed-qs/QsInputSelect';
import QsInputSelectCountry from '../question/typed-qs/QsInputSelectCountry';
import QsInputSxCollector from '../question/typed-qs/QsInputSxCollector';
import QsInputSxSelector from '../question/typed-qs/QsInputSxSelector';
import QsInputTime from '../question/typed-qs/QsInputTime';
import QsTextInput from '../question/typed-qs/QsTextInput';
import QsTextarea from '../question/typed-qs/QsTextarea';
import { useQuestion } from './use-question';
import QsInputSignature from '../question/typed-qs/QsInputSignature';
import QsStageMileMarker from '../question/typed-qs/QsStageMileMarker';
import QsInputPhoneNumber from '../question/typed-qs/QsInputPhoneNumber';

function QuestionField(
  props: QuestionProps & {
    question: TQuestion;
  },
) {
  const {
    error,
    treeKey,
    locale,
    question,
    treeState,
    updateAnswer,
    updateValue,
    onUiMetadataChange,
    mode,
  } = props;

  const { inAdminPanel } = useInAdminDashboard();

  const {
    isBodySelectTypes,
    isNumberType,
    isRange,
    isSxCollectType,
    isSxSelectType,
    isSelectTypes,
    isNumberUnitSelect,
    isCollapsible,
    isMarkdown,
  } = useQuestion(question);

  const isDisplayMode = mode === 'display';
  const { type: typeRaw, questionKey, typeOptions } = question;

  const { answerMap } = treeState || {};

  const qAnswerValue = answerMap?.[questionKey] || {};
  const values = qAnswerValue?.answerValues || [];
  const firstValue = values?.[0];
  const secondValue = values?.[1];

  if (isMarkdown || typeRaw == null) {
    return null;
  }

  const type: `${Enum_Question_Type}` = `${typeRaw}`;

  if (isBodySelectTypes) {
    return <QsInputBodySelect {...props} answerValue={qAnswerValue} />;
  }

  if (type === 'select_abd_location') {
    return (
      <QsAbdLocation question={question} answerValue={qAnswerValue} updateAnswer={updateAnswer} />
    );
  }

  if (isNumberUnitSelect) {
    return (
      <QsInputNumberUnit
        locale={locale}
        treeKey={treeKey}
        question={question}
        error={error}
        updateAnswer={updateAnswer}
        updateValue={updateValue}
        treeState={treeState}
      />
    );
  }

  if (isCollapsible) {
    return (
      <QsCollapsible
        locale={locale}
        treeKey={treeKey}
        question={question}
        value={firstValue || ''}
        error={error}
        updateAnswer={updateAnswer}
        updateValue={updateValue}
      />
    );
  }

  if (type === 'frequency') {
    return (
      <QsFrequencyInput
        value={[firstValue, secondValue]}
        onChange={(value) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: value,
          });
        }}
      />
    );
  }

  if (type === 'select_country') {
    return (
      <QsInputSelectCountry
        value={firstValue}
        onChange={(e) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerKeys: [e.target.value],
          });
        }}
      />
    );
  }

  if (type === 'select_countries') {
    return (
      <QsInputSelectCountry
        values={qAnswerValue?.answerKeys}
        multi
        onChangeValues={(nValues) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerKeys: nValues,
          });
        }}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <QsTextarea
        locale={locale}
        error={error}
        question={question}
        max={toValidNumber(typeOptions?.max)}
        maxLength={toValidNumber(typeOptions?.max)}
        value={firstValue || ''}
        updateValue={updateValue}
        onUpdate={(nValue) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [`${nValue}`],
          });
        }}
      />
    );
  }

  if (type === 'image') {
    return (
      <QsFileInput
        value={firstValue || ''}
        onChange={(e) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [e.target.value],
          });
        }}
      />
    );
  }

  if (type === 'text') {
    const restrictInput = ['firstName','lastName'].includes(question?.systemKey as string) 
      ? 'valid name' : null;
    return (
      <QsTextInput
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue || ''}
        autoComplete={getAutocompletePropForQs({ question })}
        restrictInput={restrictInput}
        onUpdate={(value) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [`${value}`],
          });
        }}
      />
    );
  }

  if (type === 'signature') {
    return (
      <QsInputSignature
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue || ''}
        autoComplete={getAutocompletePropForQs({ question })}
        onUpdate={(value) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [`${value}`],
          });
        }}
      />
    );
  }

  if (type === 'blood_pressure') {
    return (
      <QsBloodPressure
        values={values}
        onUpdate={(nValues) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: nValues,
          });
        }}
      />
    );
  }

  if (isNumberType) {
    return (
      <QsTextInput
        updateValue={updateValue}
        locale={locale}
        error={error}
        isDisplayMode={isDisplayMode}
        value={firstValue || ''}
        type='number'
        options={typeOptions}
        onUpdate={(value) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [`${value}`],
          });
        }}
      />
    );
  }

  if (isRange) {
    return (
      <QsInputRange
        value={firstValue || ''}
        options={typeOptions}
        onChange={(e) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [e.target.value],
          });
        }}
      />
    );
  }

  if (type === 'date_dob') {
    return (
      <QsInputDateDob
        question={question}
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [v],
          });
        }}
      />
    );
  }

  if (type === 'datetime_relative_onset') {
    if (!onUiMetadataChange) {
      if (inAdminPanel) {
        return null;
      }

      return (
        <div className='py-5'>
          <div className='text-error'>Property is missing for system (onUiMetadataChange)</div>
        </div>
      );
    }

    return (
      <QsInputRelativeOnset
        {...props}
        error={error}
        uiMetadata={qAnswerValue?.uiMetadata}
        onUiMetadataChange={onUiMetadataChange}
        value={firstValue}
        question={question}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: v ? [v] : [],
          });
        }}
      />
    );
  }
  if (type === 'onset_v2') {
    return (
      <QsInputOnsetV2
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [v],
          });
        }}
      />
    );
  }

  if (type === 'onset') {
    return (
      <QsInputOnset
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [v],
          });
        }}
      />
    );
  }
  if (type === 'datetime') {
    return (
      <QsInputDatetime
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [v],
          });
        }}
      />
    );
  }

  if (type === 'date') {
    return (
      <QsInputDate
        updateValue={updateValue}
        locale={locale}
        error={error}
        value={firstValue}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [v],
          });
        }}
      />
    );
  }
  if (type === 'time') {
    return (
      <QsInputTime
        value={firstValue}
        onChange={(v) => {
          if (!updateAnswer) return;
          updateAnswer({
            questionKey,
            answerValues: [v],
          });
        }}
      />
    );
  }

  if (type === 'stage_milemarker') {
    return (
      <QsStageMileMarker 
        {...props}
      />
    );
  }



  if (type === 'phone_number') {
    return (<QsInputPhoneNumber
      value={values}
      locale={locale}
      updateValue={() => { }}
      onUpdate={(value) => {
        if (!updateAnswer) return;
        updateAnswer({
          questionKey,
          answerValues: [`${value}`],
        });
      }}
    />
    )
  }

  if (isSelectTypes) {
    return <QsInputSelect {...props} />;
  }

  if (isSxSelectType) {
    return <QsInputSxSelector {...props} />;
  }

  if (isSxCollectType) {
    return <QsInputSxCollector {...props} />;
  }

  // TODO log this render cases
  return <div className='text-error'>Error: Type not supported ({type})</div>;
}

export default memo(QuestionField);
