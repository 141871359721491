import { notEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { toast } from 'react-toastify';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import Footer from '../../app/Footer';
import thumbsUp from '../../assets/ill/Thumbs_up.svg';
import LoadingAnim from '../../components/LoadingAnim';
import Logo from '../../components/Logo';
import { useOnNavChange } from '../../hooks/use-on-nav-change';
import { useData } from '../../report/use-data';
import { usePatientTransData } from '../../report/use-patient-trans-data';
import { useSurveyMeta } from '../../survey/use-survey-meta';
import { useTranslations } from '../hooks/use-translations';
import PaTreeHeader from '../tree/PaTreeHeader';

const collapCls =
  'collapse collapse-arrow border-2 border-black border-opacity-10 rounded-none first:rounded-t-xl last:rounded-b-xl border-t-0 first:border-t-2 font-base';
const collapHeaderCls = 'collapse-title text-base font-medium';
const collapBodyCls = 'collapse-content opacity-70 text-base';

function PaSurveyEndPage() {
  const [
    {
      surveyId,
      dataState: { survey, visit },
    },
  ] = useData({ disableRedirectOnInvalidReport: true });

  const { sltStr } = useStaticLocales();

  const [, { t }] = useTranslations();

  const [selectedFaqIndex, setSelectedFaqIndex] = useState<number | null>(null);

  useOnNavChange({
    block: true,
    onChange: () => {
      toast.info(sltStr({ key: 'msg-cannot-go-back' }), {
        toastId: 'msg-cannot-go-back',
      });
    },
  });

  const { transKeys, metaItems } = useSurveyMeta({
    surveyId,
    metaKey: 'faq',
  });

  const titleLabel = t('finish-page-title', {}, { fallbackValue: null });
  const subtitleLabel = t('finish-page-desc', {}, { fallbackValue: null });

  const pageKeys = survey?.metadata?.finishedPageKeys || [];
  const [{ loadingTrans }] = usePatientTransData({
    keys: [...pageKeys, ...transKeys],
  });

  if (!survey || loadingTrans) {
    return (
      <div className='flex flex-1 w-full'>
        <LoadingAnim className='h-full p-10 w-full' />
      </div>
    );
  }

  return (
    <div className='flex-1 flex-col gap-5 justify-between'>
      <div className='flex-1 flex-col gap-5'>
        <PaTreeHeader showFullBar noBack />

        <div className='px-5'>
          <div className='flex-center'>
            <Logo className='w-9' forPatient />
          </div>

          {!visit?.archived && (
            <div className='flex-center flex-col gap-4'>
              <div className='pt-12 pb-6 w-1/3'>
                <img src={thumbsUp} />
              </div>

              <div className='flex-col gap-2 flex-center-y'>
                <h4 className='font-bold sm'>{titleLabel || sltStr({ key: 'finished-title' })}</h4>

                <div className='text-base opacity-70 px-2 text-center'>
                  {subtitleLabel || sltStr({ key: 'finished-subtitle' })}
                </div>
              </div>
            </div>
          )}

          {visit?.archived && (
            <div className='flex-center flex-col gap-4 py-10'>
              <div className='flex-col gap-2 flex-center-y'>
                <div className='text-base opacity-70 px-2 text-center flex-1 bg-primary bg-opacity-10 rounded-xl w-full p-2'>
                  {sltStr({ key: 'finished-archived' })}
                </div>
              </div>
            </div>
          )}

          {/* FAQ */}
          {notEmpty(metaItems) && (
            <div className='flex-col py-4 w-full'>
              {metaItems?.map((metaItem, idx) => {
                const open = selectedFaqIndex === idx;
                return (
                  <div
                    key={metaItem.id}
                    className={classNames(collapCls, open && 'collapse-open')}
                    onClick={() => {
                      if (open) {
                        setSelectedFaqIndex(null);
                      } else {
                        setSelectedFaqIndex(idx);
                      }
                    }}
                  >
                    <div className={collapHeaderCls}>{t(metaItem.qnaQuestionKey)}</div>
                    <div className={collapBodyCls}>
                      <p>{t(metaItem.qnaAnswerKey)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className='hidden flex-none'>
        <Footer />
      </div>
    </div>
  );
}

export default memo(PaSurveyEndPage);
