import produce from 'immer';
import { isEmpty, last, set, uniq, unset, upperCase, values } from 'lodash';
import { MouseEvent, memo, useCallback, useState } from 'react';
import { BsFillPlusCircleFill, BsPlusCircle } from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';

import { isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { useOnNavChange } from '../../../../hooks/use-on-nav-change';
import {
  getMainOptionAnswerKeys,
  getMutualExclusiveAnswerKeys,
  getRequireNoteAnswerKeys,
} from '../../../../utils/models/question';
import { useTranslations } from '../../../hooks/use-translations';
import { BodySelectorProps } from '../../../pa-prop-types';
import { BodySelectMap, Keys } from '../../../patient-types';
import BackFemaleButtons from './buttons/BackFemaleButtons';
import BackLowerFemaleButtons from './buttons/BackLowerFemaleButtons';
import BackLowerMaleButtons from './buttons/BackLowerMaleButtons';
import BackMaleButtons from './buttons/BackMaleButtons';
import BackUpperFemaleButtons from './buttons/BackUpperFemaleButtons';
import BackUpperMaleButtons from './buttons/BackUpperMaleButtons';

function BodyPartSelectorBack(props: BodySelectorProps) {
  const {
    isMale,
    standalone,
    onValueChange,
    question,
    answerValue: answerValueInit,
    locale,
  } = props;

  const [, { t }] = useTranslations({ locale });

  const [answerValue, setAnswerValue] = useState(answerValueInit || {});
  const [requireNoteKey, setRequireNoteKey] = useState<string | null>(null);

  const selectedKeys = answerValue.answerKeys || [];
  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });
  const requireNoteKeys = getRequireNoteAnswerKeys({ question });
  const mainOptionKeys = getMainOptionAnswerKeys({ question });
  // const omitInSearchKeys = getOmitInSearchAnswerKeys({ question });

  const [selectedMap, setSelectedMap] = useState<BodySelectMap>({});
  const [selectedStacks, setSelectedStacks] = useState<Keys>([]);

  const selectedStack = last(selectedStacks);

  const stackKeys =
    values(selectedMap)?.reduce((p, i) => {
      return [...p, ...i];
    }, []) || [];

  const selectedButtonKeys = [...selectedKeys, ...stackKeys];

  const handleNavChange = useCallback(() => {
    if (!isEmpty(selectedStacks)) {
      setSelectedStacks((st) =>
        produce(st, (dr) => {
          dr.pop();
        })
      );
    }
  }, [selectedStacks, setSelectedStacks]);

  useOnNavChange({
    block: !standalone,
    onChange: handleNavChange,
  });

  function handleClick({ id }: { id: string }) {
    // eslint-disable-next-line
    console.log('id', id);

    const selected = selectedKeys.includes(id);
    const isMutExcl = mutExcAnswerKeys.includes(id);

    setRequireNoteKey(null);

    let nKeys: Keys = [...selectedKeys];

    if (isMutExcl) {
      // ONLY INCLUDE MUT KEY
      if (selected) {
        nKeys = [];
      } else {
        nKeys = [id];
      }
    } else {
      // EXCLUDE ALL MUT KEYS
      nKeys = nKeys.filter((k) => !mutExcAnswerKeys.includes(k));
      // eslint-disable-next-line no-lonely-if
      if (selected) {
        nKeys = nKeys.filter((i) => i !== id);
      } else {
        nKeys = uniq([...nKeys, id]);
      }
    }

    const nValue = produce(answerValue, (dr) => {
      dr.answerKeys = nKeys;
    });

    setAnswerValue(nValue);
    onValueChange(nValue);

    setSelectedMap((st) => {
      if (isMutExcl) {
        return {};
      }

      const nMap = produce(st, (draft) => {
        if (!selected) {
          set(draft, id, selectedStacks);
        } else {
          unset(draft, id);
        }
      });

      return nMap;
    });
  }

  function handleLeafItemClick(e: MouseEvent<unknown, unknown>) {
    const el = e.target as HTMLElement;
    const id = el.getAttribute('id');

    if (id) {
      handleClick({ id });
    }
  }

  function handleNavItemClick(e: MouseEvent<unknown, unknown>) {
    const el = e.target as HTMLElement;
    const id = el.getAttribute('id');
    if (id) {
      // eslint-disable-next-line
      console.log('id', id);

      setSelectedStacks([id]);
    }
  }

  return (
    <div className='t_BodyPartSelectorBack flex-col overflow-hidden'>
      <div className={classNames('flex-col overflow-auto', !standalone && 'p-4')}>
        {/* BACK NAVI */}
        {selectedStack && (
          <div>
            <div className='pb-4 pt-0'>
              <span
                className='cursor-pointer flex-center-y flex gap-4'
                onClick={() => {
                  setSelectedStacks([]);
                }}
              >
                <FaArrowLeft size={13} />
                <span className='opacity-80'>{upperCase(t(selectedStack))}</span>
              </span>
            </div>
          </div>
        )}

        {isNotEmpty(mainOptionKeys) && (
          <div className='flex gap-2 flex-wrap'>
            {mainOptionKeys?.map((mutKey) => {
              const isRequireNote = requireNoteKeys.includes(mutKey);
              const selected = selectedKeys.includes(mutKey);

              return (
                <button
                  key={mutKey}
                  className='flex-1 btn-base'
                  onClick={() => {
                    if (!selected) {
                      setRequireNoteKey(null);

                      if (isRequireNote) {
                        setRequireNoteKey(mutKey);
                        return;
                      }
                    }

                    handleClick({ id: mutKey });
                  }}
                >
                  {selected ? (
                    <BsPlusCircle size={22} className='text-primary rotate-45' />
                  ) : (
                    <BsFillPlusCircleFill size={22} className='text-primary' />
                  )}
                  <span className='text-base'>{upperCase(t(mutKey))}</span>
                </button>
              );
            })}
          </div>
        )}

        {requireNoteKey && (
          <div className='flex-none flex pt-3 gap-2'>
            <label className='mui-input flex-1'>
              <input
                placeholder=''
                value={answerValue?.notesForKeys?.[requireNoteKey] || ''}
                onChange={(e) => {
                  const nValue = produce(answerValue, (dr) => {
                    if (!dr.notesForKeys) dr.notesForKeys = {};
                    dr.notesForKeys[requireNoteKey] = e.target.value;
                  });

                  setAnswerValue(nValue);
                }}
              />
              <span>AREA DESCRIPTION</span>
            </label>

            <div className='flex-none'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  if (!isNotEmpty(answerValue?.notesForKeys?.[requireNoteKey])) {
                    return;
                  }

                  handleClick({ id: requireNoteKey });
                }}
              >
                ADD
              </button>
            </div>
          </div>
        )}

        <div className='relative max-w-full pt-2'>
          <div className='py-5'>
            <div className='flex-center'>
              {/* SELECT IMAGE */}
              {!selectedStack && (
                <>
                  {isMale ? (
                    <BackMaleButtons
                      selectedKeys={selectedButtonKeys}
                      className='relative left-2'
                      onClick={handleNavItemClick}
                    />
                  ) : (
                    <BackFemaleButtons
                      selectedKeys={selectedButtonKeys}
                      className='relative left-2'
                      onClick={handleNavItemClick}
                    />
                  )}
                </>
              )}
              {selectedStack === 'o-back-upper' &&
                (isMale ? (
                  <BackUpperMaleButtons
                    className='relative left-2'
                    selectedKeys={selectedButtonKeys}
                    onClick={handleLeafItemClick}
                  />
                ) : (
                  <BackUpperFemaleButtons
                    className='relative left-2'
                    selectedKeys={selectedButtonKeys}
                    onClick={handleLeafItemClick}
                  />
                ))}
              {selectedStack === 'o-back-lower' && (
                <>
                  {isMale ? (
                    <BackLowerMaleButtons
                      className='relative left-2'
                      selectedKeys={selectedButtonKeys}
                      onClick={handleLeafItemClick}
                    />
                  ) : (
                    <BackLowerFemaleButtons
                      className='relative left-2'
                      selectedKeys={selectedButtonKeys}
                      onClick={handleLeafItemClick}
                    />
                  )}
                </>
              )}
            </div>
            <div className='absolute flex-center top-0 w-full flex justify-between opacity-60 pointer-events-none'>
              <span className='p-5 text-sm'>LEFT</span>
              <span className='p-5 text-sm'>RIGHT</span>
            </div>
          </div>
        </div>
      </div>

      {!isEmpty(selectedKeys) && (
        <div
          className={classNames('flex-none flex-col pt-4 gap-3 border-t', !standalone && 'px-4')}
        >
          <div className='flex gap-3 flex-wrap'>
            {selectedKeys.map((answerKey) => {
              const note = answerValue?.notesForKeys?.[answerKey];
              return (
                <button
                  key={answerKey}
                  className='btn btn-outline btn-primary border-primary p-2 min-h-min h-9 flex gap-2'
                  onClick={() => {
                    handleClick({ id: answerKey });
                  }}
                >
                  <span className='uppercase'>{t(answerKey)}</span>
                  {note ? ` (${note})` : null}
                  <MdClose size={16} />
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(BodyPartSelectorBack);
