import {
  Enum_Patientvisit_Status,
  PatientVisitInput,
  getChecksum,
  useUpdatePatientVisitMutation,
} from '@cyren/common-lib';
import produce from 'immer';
import { difference, first, intersection, isEmpty, last, size, union } from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { atom, useRecoilState } from 'recoil';
import { useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import { AppStateCookie } from '../../app/use-app-state';
import { PaDataRepoState, useData } from '../../report/use-data';
import { onError } from '../../utils/apollo-utils';
import { getVisitFormDataFromSystemValueMap } from '../../utils/models/question';
import { NavParamPaSurvey, PaSurveyStateType, ReportStateType } from '../patient-types';
import { PaErrorState, PaReportState } from './use-report';
import { RecordErrorHandler } from '../pa-prop-types';
import { useTreeHelpers } from './use-tree-helpers';

export const PaSurveyState = atom<PaSurveyStateType>({
  key: 'PaSurveyState',
  default: {
    adaptedTreeKeys: [],
  },
});

export function usePaSurveyParams() {
  return useParams<NavParamPaSurvey>();
}

export function useSurveyState() {
  const params = usePaSurveyParams();
  const { orgId, shortId, treeKey } = params;

  const nav = useNavigate();
  const [, { getTreeByKey }] = useData();


  const [{ visit }] = useRecoilState(PaDataRepoState);
  const [surveyState, setSurveyState] = useRecoilState(PaSurveyState);
  const [reportState, setReportState] = useRecoilState(PaReportState);
  const [errorState, setErrorState] = useRecoilState(PaErrorState);
  const [{ survey }] = useRecoilState(PaDataRepoState);
  const { sltStr } = useStaticLocales();

  const [{ localeKey }] = useRecoilState(AppStateCookie);

  const [, { isTreeVisible }] = useTreeHelpers({ treeState: first(reportState.treeStates) });

  useEffect(() => {
    if (reportState.locale !== localeKey) {
      setReportState((st) =>
        produce(st, (dr) => {
          dr.locale = localeKey;
        }),
      );
    }
  }, [localeKey]);

  const surveyId = survey?.id;

  const baseUrl = `/s/${orgId}/${shortId}`;

  const { questionTreeKeys } = survey || {};
  const { curTreeKey } = surveyState;

  const curTreeIdx = questionTreeKeys?.findIndex((k) => k === curTreeKey);
  const isLastTree = !isEmpty(questionTreeKeys) && last(questionTreeKeys) === curTreeKey;

  function setCurTreeKey(nTreeKey: string) {
    const nState = produce(surveyState, (draft) => {
      draft.curTreeKey = nTreeKey;
    });

    setSurveyState(nState);
  }

  function getStageProgress(nState: ReportStateType, transitioningToTreeKey: string | null | undefined, userCompleted: boolean) {

    const completedStages: string[] = [];
    let activeStage: string | null = null;

    // User will not necessarily see all treeKeys (some may be disabled or hidden from user),
    // but they can also move forwards and then move backwards.  Create a list of all possible
    // top-level tree keys that they have actually seen, which will give us a sense of their
    // top level progress
    const seenTreeKeys = union(nState.treeStates.map(i => i.treeKey),
      transitioningToTreeKey ? [transitioningToTreeKey] : []);
    const seenTrees = intersection(survey?.questionTreeKeys, seenTreeKeys).map(i => getTreeByKey(i));


    seenTrees?.forEach(tree => {
      if (tree?.stageBoundary) {
        if (activeStage) {
          completedStages.push(activeStage);
        }
        activeStage = tree.treeKey;
      }
    });

    if (userCompleted && activeStage) {
      completedStages.push(activeStage);
      activeStage = null;
    }

    const allPossibleStages = survey?.questionTreeKeys?.
      map(aTreeKey => getTreeByKey(aTreeKey)).
      filter(tree => tree?.stageBoundary).
      map(tree => tree?.treeKey);

    const unreachedStages = difference(allPossibleStages,
      [...completedStages, activeStage]);

    const result = {
      completedStages,
      activeStage,
      unreachedStages
    }
    // console.log('stage progress', result);
    return result;
  }

  const [updatePatientVisit] = useUpdatePatientVisitMutation({
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err.message.includes('ERROR-011')) {
        toast.error(sltStr({ key: 'msg-cannot-go-back' }), {
          toastId: 'msg-cannot-go-back',
        });
        nav(`${baseUrl}/finished`);
        return;
      }
      onError(err);
    },
  });

  const deployed = survey?.deployment?.id != null;

  const updateVisit = (
    nState: ReportStateType,
    { data }: { data?: PatientVisitInput } | undefined = {},
    transitioningToTreeKey: string | null = null
  ) => {
    if (visit?.id != null) {
      const visitFormData = getVisitFormDataFromSystemValueMap({
        systemValueMap: nState?.systemValueMap,
      });

      // This is useful debugging code that can show the specific user answer fields and
      // values being written to the server.  Leaving the code in place so that it can
      // be uncommented for debugging
      /*
      console.log('writing report state to server:', reportState);
      let result = '';
      nState.treeStates.forEach((i) => {
        if (i && i.answerMap && i.answerMap) {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(i.answerMap)) {
            let displayValue = null;
            if (value && value?.answerKeys) {
              displayValue = `${value.answerKeys[0]}`
            }
            if (value && value.answerValues) {
              displayValue += `:${value.answerValues[0]}`
            }
            result += `send=> ${key}: ${displayValue}\n`;
          }
        }
      });
      console.log(result);
      */

      const userCompleted = data?.status === Enum_Patientvisit_Status.Reported;

      updatePatientVisit({
        variables: {
          id: visit?.id,
          data: {
            ...data,
            ...visitFormData,
            deployed,
            organization: survey?.organization?.id,
            enumSurveyType: survey?.type,
            surveyState: {
              reportState: {
                ...nState,
                errorState
              },
              surveyState: {
                ...surveyState,
                ...getStageProgress(nState, transitioningToTreeKey, userCompleted)
              },
              checksum: getChecksum(nState),
            },
          },
        },
      });
    }
  };

  function startSurvey() {
    const firstTreeKey = first(questionTreeKeys);
    nav(`${baseUrl}/${firstTreeKey}`);

    if (visit?.id) {
      updateVisit(reportState, {
        data: {
          deployed,
          organization: survey?.organization?.id,
          enumSurveyType: survey?.type,
          visitedAt: new Date(),
          status: Enum_Patientvisit_Status.Reporting,
        },
      }, firstTreeKey);
    }
  }

  function onSave() {
    updateVisit(reportState, { data: { status: Enum_Patientvisit_Status.Reported } });

    nav(`/s/${orgId}/${shortId}/finished`);
  }

  function goNextTree() {
    if (curTreeIdx != null && curTreeIdx >= 0) {
      let nextTreeKeyIdx = curTreeIdx;
      let destinationTreeKey = null;

      while (!destinationTreeKey && survey && nextTreeKeyIdx < (survey.questionTreeKeys.length - 1)) {
        nextTreeKeyIdx++;
        const nextTreeKey = survey?.questionTreeKeys[nextTreeKeyIdx];
        if (nextTreeKey && isTreeVisible({ treeKey: nextTreeKey })) {
          destinationTreeKey = nextTreeKey;
        }
      }

      // none of the remaining trees are accessible, so switch over to a save operation
      if (!destinationTreeKey) {
        const yes = window.confirm(sltStr({ key: 'msg-confirm-save' }));
        if (!yes) {
          return;
        }

        onSave();
        return;
      }

      updateVisit(reportState, {}, destinationTreeKey);

      nav(`${baseUrl}/${destinationTreeKey}`);
    }
  }

  const recordUnhandledError: RecordErrorHandler = ({ message }) => {
    if (errorState?.maxErrorsReached) {
      // don't flood the system with error message
      return;
    }

    // don't put more than 20 errors into the queue
    const atMaxErrors = (size(errorState.unhandledErrors) + 1) >= 20
    setErrorState({
      ...errorState,
      maxErrorsReached: atMaxErrors,
      unhandledErrors: [...errorState.unhandledErrors,
      {
        createdOn: new Date(Date.now()).toISOString(),
        message: message.message,
        source: message.source,
        lineInfo: `${message.lineno}:${message.colno}`
      }
      ]
    });
  };

  function onInterimSave() {
    if (curTreeIdx != null && curTreeIdx >= 0) {
      if (reportState.systemValueMap?.dob === '') {
        // don't allow an interim report that has a blank dob to be written to the server, since this
        // will generate a graphql error regarding an invalid state
        // eslint-disable-next-line
        console.log('Invalid dob, ignoring interim save request');
        return;
      }
      updateVisit(reportState);
    }
  }

  // init
  // useEffect(() => {
  //   if (isEmpty(flowState.survey) && flowKey) {
  //     const nFlow = getFlowByKey(flowKey);

  //     if (nFlow) {
  //       const nState = produce(flowState, (draft) => {
  //         draft.survey = nFlow;
  //         draft.adaptedTreeKeys = nFlow.treeKeys;
  //       });

  //       setFlowState(nState);
  //     }
  //   }
  // }, [flowKey]);

  // update curTreeKey on navigate (or back button)
  useEffect(() => {
    if (survey && treeKey && curTreeKey !== treeKey) {
      setCurTreeKey(treeKey);
    }
  }, [survey, treeKey, curTreeKey]);

  return [
    { surveyId, reportState, surveyState, curTreeIdx, isLastTree, params },
    {
      updateVisit,
      setReportState,
      navigate: nav,
      startSurvey,
      goNextTree,
      onSave,
      onInterimSave,
      setCurTreeKey,
      setSurveyState,
      recordUnhandledError
    },
  ] as const;
}
