import { memo, useState } from 'react';

import { isNotEmpty } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { last, uniq, upperCase } from 'lodash';
import { BsFillPlusCircleFill, BsPlusCircle } from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';
import { useToastMsgs } from '../../../../admin/hooks/use-toast-msgs';
import LoadingAnim from '../../../../components/LoadingAnim';
import { useData } from '../../../../report/use-data';
import {
  getMainOptionAnswerKeys,
  getMutualExclusiveAnswerKeys,
  getRequireNoteAnswerKeys,
} from '../../../../utils/models/question';
import SelectItem from '../../../components/SelectItem';
import { useErrorState } from '../../../hooks/use-error-state';
import { useGlobalPaState } from '../../../hooks/use-global-pa-state';
import { usePaQuestions } from '../../../hooks/use-pa-questions';
import { useTranslations } from '../../../hooks/use-translations';
import { BodySelectorProps } from '../../../pa-prop-types';
import { Key, Keys } from '../../../patient-types';
import BellyMaleButtons from './buttons/BellyMaleButtons';
import BottomFemaleButtons from './buttons/BottomFemaleButtons';
import BottomMaleButtons from './buttons/BottomMaleButtons';
import FaceMaleButtons from './buttons/FaceMaleButtons';
import FootLeftButtons from './buttons/FootLeftButtons';
import FootRightButtons from './buttons/FootRightButtons';
import HandLeftButtons from './buttons/HandLeftButtons';
import HandRightButtons from './buttons/HandRightButtons';
import LeftArmMaleButtons from './buttons/LeftArmMaleButtons';
import LegLeftButtons from './buttons/LegLeftButtons';
import LegRightButtons from './buttons/LegRightButtons';
import MouthButtons from './buttons/MouthButtons';
import MouthLiftButtons from './buttons/MouthLiftButtons';
import RightArmMaleButtons from './buttons/RightArmMaleButtons';
import WholeBodyBackLowerMaleButtons from './buttons/WholeBodyBackLowerMaleButtons';
import WholeBodyBackUpperMaleButtons from './buttons/WholeBodyBackUpperMaleButtons';
import WholeChestFemaleButtons from './buttons/WholeChestFemaleButtons';
import WholeChestMaleButtons from './buttons/WholeChestMaleButtons';

/**
 * NOTE
 * Renders body part selector in whole body selector
 * Responsible to render the correct part on selected deeper selector option
 */

export const optionToQsMap = {
  'o-chest': 'q-whole-body-chest',
  'o-face': 'q-whole-body-face',
  'o-mouth': 'q-whole-body-mouth',
  'o-abdomen': 'q-select-body-belly',
  'o-bottom': 'q-whole-body-bottom',
  'o-arm-rt': 'q-whole-body-arm-rt',
  'o-arm-lt': 'q-whole-body-arm-lt',
  'o-hand-rt': 'q-whole-body-hand-rt',
  'o-hand-lt': 'q-whole-body-hand-lt',
  'o-leg-rt': 'q-whole-body-leg-rt',
  'o-leg-lt': 'q-whole-body-leg-lt',
  'o-foot-rt': 'q-whole-body-foot-rt',
  'o-foot-lt': 'q-whole-body-foot-lt',
  'o-head': 'q-whole-body-head',
  'o-neck': 'q-whole-body-neck',
  'o-back-upper': 'q-whole-body-back-upper',
  'o-back-lower': 'q-whole-body-back-lower',
} as const;

export type BodyPartQsOptionKeys = keyof typeof optionToQsMap;

function BodyPartSelectorWholeBodySubpart(
  props: BodySelectorProps & {
    optionKey: BodyPartQsOptionKeys;
    questionKey: Key;
    selectedStack: Keys;
    stackMapKeys: Keys;
    setSelectedStack: (nKeys: Keys) => void;
  }
) {
  const {
    stackMapKeys,
    selectedStack,
    setSelectedStack,
    questionKey,
    optionKey,
    onValueChange,
    answerValue,
    locale,
  } = props;

  const [, { getQuestionByKey }] = useData();
  const { showRequireNote } = useToastMsgs();
  const [{ isMale }] = useGlobalPaState();

  const question = getQuestionByKey(questionKey);

  const [tongueLift, setTongueLift] = useState(false);

  const { errorState, setErrorState } = useErrorState({
    containerKey: 'whole-body',
  });

  // Load question
  const [{ fetching }] = usePaQuestions({
    keys: [questionKey],
    locale,
  });

  const [, { t }] = useTranslations({ locale });

  const [requireNoteKey, setRequireNoteKey] = useState<string | null>(null);

  if (!question || fetching) {
    // if (!loading) {
    return (
      <div className='min-h-[20rem] flex-center'>
        <LoadingAnim />
      </div>
    );
  }

  const { answerKeys, optionConditions } = question;
  const answerOptionsMap = question?.answerOptionsMap || {};

  const lastSelectedStackOption = last(selectedStack);

  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });
  const requireNoteKeys = getRequireNoteAnswerKeys({ question });
  const mainOptionKeys = getMainOptionAnswerKeys({ question });

  // const omitInSearchKeys = getOmitInSearchAnswerKeys({ question });

  const selectedKeys = answerValue?.answerKeys || [];
  const notesForKeys = answerValue?.notesForKeys || {};
  const selectedButtonKeys = uniq([...selectedKeys, ...selectedStack, ...stackMapKeys]);

  function handleClick({ id }: { id: string }) {
    // eslint-disable-next-line
    console.log('id', id);

    const selected = selectedKeys.includes(id);
    const isMutExcl = mutExcAnswerKeys?.includes(id);

    setRequireNoteKey(null);

    let nKeys: Keys = [...selectedKeys];

    if (isMutExcl) {
      // ONLY INCLUDE MUT KEY
      if (selected) {
        nKeys = [];
      } else {
        nKeys = [id];
      }
    } else {
      // EXCLUDE ALL MUT KEYS
      nKeys = nKeys.filter((k) => !mutExcAnswerKeys.includes(k));
      // eslint-disable-next-line no-lonely-if
      if (selected) {
        nKeys = nKeys.filter((i) => i !== id);
      } else {
        nKeys = uniq([...nKeys, id]);
      }
    }

    const nValue = produce(answerValue || {}, (dr) => {
      dr.answerKeys = nKeys;
    });

    onValueChange(nValue);
  }

  const showRLsigns =
    optionKey === 'o-abdomen' ||
    optionKey === 'o-chest' ||
    optionKey === 'o-bottom' ||
    optionKey === 'o-face' ||
    optionKey === 'o-mouth';

  return (
    <>
      {/* BACK NAVI */}
      <div>
        <div className='pb-4 pt-0'>
          <span
            className='cursor-pointer flex-center-y flex gap-4'
            onClick={() => {
              setErrorState({ errorKeys: [] });

              const invalidNoteKey = requireNoteKeys.find((aKey) => {
                if (!selectedKeys?.includes(aKey)) return false;

                const note = notesForKeys?.[aKey];
                return !isNotEmpty(note);
              });

              if (invalidNoteKey) {
                showRequireNote();

                const nKeys = produce(errorState.errorKeys, (dr) => {
                  dr.push(invalidNoteKey);
                });

                setErrorState({ errorKeys: nKeys });

                return;
              }

              const nextStack = [...selectedStack];
              nextStack.pop();
              setSelectedStack(nextStack);
            }}
          >
            <FaArrowLeft size={13} />
            <span className='opacity-80 uppercase'>{t(lastSelectedStackOption)}</span>
          </span>
        </div>
      </div>

      <div className='flex-col overflow-hidden'>
        <div className={classNames('flex-col overflow-hidden')}>
          {isNotEmpty(mainOptionKeys) && (
            <div className='flex gap-2 flex-wrap'>
              {mainOptionKeys?.map((mutKey) => {
                const isRequireNote = requireNoteKeys.includes(mutKey);
                const selected = selectedKeys.includes(mutKey);

                // TODO handle condition match in common lib
                const inclusiveConds = optionConditions?.filter((c) => {
                  return c.type === 'include' && c.keys?.includes(mutKey);
                });

                if (isNotEmpty(inclusiveConds)) {
                  const matched = inclusiveConds.find((c) => {
                    if (c.func === 'isFemale' && !isMale) {
                      return true;
                    }
                    return false;
                  });
                  if (!matched) {
                    return null;
                  }
                }

                return (
                  <button
                    key={mutKey}
                    className='flex-1 btn-base'
                    onClick={() => {
                      if (!selected) {
                        setRequireNoteKey(null);

                        if (isRequireNote) {
                          setRequireNoteKey(mutKey);
                          return;
                        }
                      }

                      handleClick({ id: mutKey });
                    }}
                  >
                    {selected ? (
                      <BsPlusCircle size={22} className='text-primary rotate-45' />
                    ) : (
                      <BsFillPlusCircleFill size={22} className='text-primary' />
                    )}
                    <span className='text-base'>{upperCase(t(mutKey))}</span>
                  </button>
                );
              })}
            </div>
          )}

          {requireNoteKey && (
            <div className='flex-none flex pt-3 gap-2'>
              <label className='mui-input flex-1'>
                <input
                  placeholder=''
                  value={notesForKeys?.[requireNoteKey] || ''}
                  onChange={(e) => {
                    const nValue = produce(answerValue || {}, (dr) => {
                      if (!dr.notesForKeys) dr.notesForKeys = {};
                      dr.notesForKeys[requireNoteKey] = e.target.value;
                    });

                    onValueChange(nValue);
                  }}
                />
                <span>AREA DESCRIPTION</span>
              </label>

              <div className='flex-none'>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    if (!isNotEmpty(answerValue?.notesForKeys?.[requireNoteKey])) {
                      return;
                    }

                    handleClick({ id: requireNoteKey });
                  }}
                >
                  ADD
                </button>
              </div>
            </div>
          )}

          <div className='t_BodyPartSelectorWholeBodySubpart flex-1 overflow-auto'>
            <div
              className={
                classNames('relative max-w-full ')
                // 'pb-1', showRLsigns ? 'pt-14' : 'pt-8'
              }
            >
              {showRLsigns && (
                <div className='absolute flex-center pt-1 w-full flex justify-between opacity-60'>
                  <span className='p-5 text-sm'>RIGHT</span>
                  <span className='p-5 text-sm'>LEFT</span>
                </div>
              )}

              {(optionKey === 'o-back-lower' || optionKey === 'o-back-upper') && (
                <div className='absolute flex-center pt-1 w-full flex justify-between opacity-60'>
                  <span className='p-5 text-sm'>LEFT</span>
                  <span className='p-5 text-sm'>RIGHT</span>
                </div>
              )}

              {/* PART IMAGE */}
              {isMale && optionKey === 'o-chest' && (
                <div className='flex-center relative pt-10'>
                  <WholeChestMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {!isMale && optionKey === 'o-chest' && (
                <div className='flex-center relative pt-10'>
                  <WholeChestFemaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-face' && (
                <div className='flex-center relative pt-10'>
                  <FaceMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-mouth' && (
                <div className='flex-center relative pt-10'>
                  {tongueLift ? (
                    <MouthLiftButtons
                      selectedKeys={selectedButtonKeys}
                      className='relative'
                      onClick={(e) => {
                        const el = e.target as HTMLElement;
                        const id = el.getAttribute('id');
                        if (id) {
                          handleClick({ id });
                        }
                      }}
                    />
                  ) : (
                    <MouthButtons
                      selectedKeys={selectedButtonKeys}
                      className='relative'
                      onClick={(e) => {
                        const el = e.target as HTMLElement;
                        const id = el.getAttribute('id');
                        if (id) {
                          handleClick({ id });
                        }
                      }}
                    />
                  )}
                </div>
              )}
              {optionKey === 'o-abdomen' && (
                <div className='flex-center relative pt-10'>
                  <BellyMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {isMale && optionKey === 'o-bottom' && (
                <div className='flex-center relative pt-10'>
                  <BottomMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {!isMale && optionKey === 'o-bottom' && (
                <div className='flex-center relative pt-10'>
                  <BottomFemaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-arm-rt' && (
                <div className='flex-center relative pt-10'>
                  <RightArmMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-arm-lt' && (
                <div className='flex-center relative pt-10'>
                  <LeftArmMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-hand-rt' && (
                <div className='flex-center relative pt-10'>
                  <HandRightButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-hand-lt' && (
                <div className='flex-center relative pt-10'>
                  <HandLeftButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-leg-rt' && (
                <div className='flex-center relative pt-10'>
                  <LegRightButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-leg-lt' && (
                <div className='flex-center relative pt-10'>
                  <LegLeftButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-foot-rt' && (
                <div className='flex-center relative pt-10 pr-10'>
                  <FootRightButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-foot-lt' && (
                <div className='flex-center relative pt-10 pl-10'>
                  <FootLeftButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-back-upper' && (
                <div className='flex-center relative pt-4'>
                  <WholeBodyBackUpperMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {optionKey === 'o-back-lower' && (
                <div className='flex-center relative pt-4'>
                  <WholeBodyBackLowerMaleButtons
                    selectedKeys={selectedButtonKeys}
                    className='relative'
                    onClick={(e) => {
                      const el = e.target as HTMLElement;
                      const id = el.getAttribute('id');
                      if (id) {
                        handleClick({ id });
                      }
                    }}
                  />
                </div>
              )}
              {(optionKey === 'o-neck' || optionKey === 'o-head') && (
                <div className='flex-col gap-3 overflow-auto'>
                  {answerKeys?.map((answerKey) => {
                    const optionChecked = selectedKeys?.includes(answerKey);
                    const requireNoteInput =
                      optionChecked && answerOptionsMap?.[answerKey]?.requireNote;

                    const note = notesForKeys?.[answerKey] || '';

                    const error = errorState.errorKeys.includes(answerKey);

                    return (
                      <SelectItem
                        type={'checkbox'}
                        key={answerKey}
                        answerKey={answerKey}
                        questionKey={questionKey}
                        locale={locale}
                        selectedKeys={selectedKeys}
                        requireNoteInput={requireNoteInput}
                        note={note}
                        error={error ? 'required' : undefined}
                        onChange={(e) => {
                          const { checked } = e.target;

                          const needNoteInput = answerOptionsMap?.[answerKey]?.requireNote;

                          if (needNoteInput) {
                            // prevent moving on when require note
                            if (checked && !isNotEmpty(note)) {
                              setErrorState((st) =>
                                produce(st, (dr) => {
                                  dr.errorKeys.push(answerKey);
                                  dr.errorKeys = uniq(dr.errorKeys);
                                })
                              );
                            }

                            if (!checked) {
                              setErrorState((st) =>
                                produce(st, (dr) => {
                                  dr.errorKeys = dr.errorKeys.filter((n) => n !== answerKey);
                                })
                              );
                            }
                          }

                          const nValue = produce(answerValue || {}, (dr) => {
                            if (checked) {
                              dr.answerKeys = uniq([...(dr.answerKeys || []), answerKey]);
                            } else {
                              dr.answerKeys = (dr.answerKeys || []).filter((n) => n !== answerKey);

                              if (!dr.notesForKeys) dr.notesForKeys = {};
                              delete dr.notesForKeys[answerKey];
                            }
                          });

                          onValueChange(nValue);
                        }}
                        onNoteChange={(nNote) => {
                          const nValue = produce(answerValue || {}, (dr) => {
                            if (!dr.notesForKeys) dr.notesForKeys = {};
                            dr.notesForKeys[answerKey] = nNote;
                          });

                          onValueChange(nValue);

                          if (!isNotEmpty(nNote)) {
                            setErrorState((st) =>
                              produce(st, (dr) => {
                                dr.errorKeys.push(answerKey);
                                dr.errorKeys = uniq(dr.errorKeys);
                              })
                            );
                          } else {
                            setErrorState((st) =>
                              produce(st, (dr) => {
                                dr.errorKeys = dr.errorKeys.filter((n) => n !== answerKey);
                              })
                            );
                          }
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>

            {optionKey === 'o-mouth' && (
              <div className='flex-center'>
                <button
                  className='btn'
                  onClick={() => {
                    setTongueLift(!tongueLift);
                  }}
                >
                  {!tongueLift ? 'LIFT TONGUE' : 'LOWER TONGUE'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(BodyPartSelectorWholeBodySubpart);
