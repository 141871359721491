import classNames from 'classnames';
import produce from 'immer';
import { first } from 'lodash';
import { memo } from 'react';
import Footer from '../../app/Footer';
import PaSurveyHeader from '../../app/PaSurveyHeader';
import LoadingAnim from '../../components/LoadingAnim';
import { useData } from '../../report/use-data';
import { usePatientTreeData } from '../../report/use-patient-tree-data';
import { useDefaultStates } from '../hooks/use-default-states';
import { usePaSurveyParams, useSurveyState } from '../hooks/use-survey-state';

import { SystemLocaleKeys, useStaticLocales } from '../../admin/hooks/locales/use-static-locale';
import config from '../../config';
import { usePatientTransData } from '../../report/use-patient-trans-data';
import { useTranslations } from '../hooks/use-translations';
import il1 from './assets/Doctor.svg';
import il2 from './assets/HealthcareWorker.svg';

function PaSurveyHomePage() {
  const [{ dataState, isLoading }] = useData();
  const [{ reportState }, { setReportState, startSurvey }] = useSurveyState();
  const [{ initDemo }] = useDefaultStates();
  const { slt } = useStaticLocales();
  const [, { t }] = useTranslations();
  const { referralMessageId } = usePaSurveyParams();

  // show a different hero image for addon forms (which have referral message Ids)
  // in order to avoid confusing the user
  const heroImage = referralMessageId ? il2 : il1;

  const { org, survey } = dataState;

  // preload first tree
  const firstTreeKey = first(survey?.questionTreeKeys);

  usePatientTreeData({
    autoRefetch: true,
    treeKey: firstTreeKey,
  });

  const firstPageKeys = survey?.metadata?.frontPageKeys || [];

  const [{ loadingTrans }] = usePatientTransData({
    keys: firstPageKeys,
  });

  const isValid = reportState.acceptedTerms;

  if (loadingTrans || org == null || survey == null || isLoading) {
    return (
      <div className='flex flex-1 w-full'>
        <LoadingAnim className='h-full p-10 w-full' />
      </div>
    );
  }

  const titleKeyAcep = 'survey-title-acep';
  const titleKeyYc = 'survey-title-yc';
  const titleKeyCommon = 'survey-title';

  let titleKey: SystemLocaleKeys = survey.desc?.includes('#yc') ? titleKeyYc : titleKeyCommon;
  titleKey = survey.desc?.includes('#acep') ? titleKeyAcep : titleKeyCommon;

  const subtitleKeyAcep = 'survey-subtitle-acep';
  const subtitleKeyCommon = 'survey-subtitle';

  const subtitleKey: SystemLocaleKeys = survey.desc?.includes('#acep')
    ? subtitleKeyAcep
    : subtitleKeyCommon;

  const titleLabel = t('front-page-title', {}, { fallbackValue: null });
  const subtitleLabel = t('front-page-desc', {}, { fallbackValue: null });

  return (
    <div className='flex-col flex-1 w-full gap-5 px-5 pt-2'>
      <div className='flex-col flex-1 w-full gap-6'>
        <PaSurveyHeader />

        <div className='text-xl flex-col gap-3'>
          <div className='' />

          <img src={heroImage} className='w-[40%]' />

          <div className='h-1' />

          <h4 className='font-bold sm'>{titleLabel || slt({ key: titleKey })}</h4>
          <p className='md text-light'>{subtitleLabel || slt({ key: subtitleKey })}</p>
        </div>

        <div className='form-control'>
          <label className='flex cursor-pointer label gap-3 items-start'>
            <input
              type='checkbox'
              checked={reportState.acceptedTerms}
              className='checkbox checkbox-primary checkbox-lg'
              onChange={(e) => {
                setReportState((st) =>
                  produce(st, (dr) => {
                    dr.acceptedTerms = e.target.checked;
                  }),
                );
              }}
            />
            <div className='flex-1 flex-center-y h-full'>
              <span className='label-text text-light text-base'>
                {slt({
                  key: 'agree-msg',
                  vars: [
                    <a
                      key='v1'
                      href={config.termsOfService}
                      target='_blank'
                      rel='noreferrer'
                      className='link whitespace-nowrap'
                    >
                      {slt({ key: 'terms-of-Use' })}
                    </a>,
                    <a
                      key='v2'
                      href={config.privacyPolicy}
                      target='_blank'
                      rel='noreferrer'
                      className='link whitespace-nowrap'
                    >
                      {slt({ key: 'privacy-policy' })}
                    </a>,
                  ],
                })}
              </span>
            </div>
          </label>
        </div>

        <div className='flex-col gap-2'>
          <button
            className={classNames('btn btn-lg btn-primary', !isValid && 'btn-disabled')}
            onClick={() => {
              if (!isValid) return;
              initDemo(false);
              startSurvey();
            }}
          >
            {slt({ key: 'btn-start' })}
          </button>
        </div>

        {/* <div className='h-5' /> */}
      </div>
      <Footer />
    </div>
  );
}

export default memo(PaSurveyHomePage);
