import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SyntheticEvent, memo, useState } from "react";
import { size } from "lodash";
import { useSendVerificationCodeMutation, useValidateVerificationCodeMutation } from "@cyren/common-lib";
import { useData } from "../../../../report/use-data";
import produce from "immer";
import { useStaticLocales } from "../../../../admin/hooks/locales/use-static-locale";

function ModalValidateSMS(props: {
  messageId: string;
  recipientAddress: string;
  onClose: (success: boolean) => void;
}) {
  const { messageId, onClose, recipientAddress } = props;

  const [
    {
      dataState: { visit },
    },
    {
      setDataState
    }
  ] = useData();


  const [validationCode, setValidationCode] = useState<string>("");
  const [activeMessageId, setActiveMessageId] = useState<string>(messageId);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);

  const [sendVerificationCode, { loading: loadingSend }] = useSendVerificationCodeMutation();
  const [validateVerificationCode, { loading: loadingValidate }] = useValidateVerificationCodeMutation();

  const { sltStr } = useStaticLocales();

  const handleClose = (event: SyntheticEvent, reason?: "backdropClick" | "escapeKeyDown") => {
    if (reason !== 'backdropClick') {
      onClose(false);
    }
  };


  return (
    <Dialog open
      onClose={handleClose}
      maxWidth="sm"
      fullWidth >
      <DialogTitle>
        {sltStr({ key: 'msg-verify-phone' })}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>{sltStr({ key: 'msg-verify-phone-desc' })}</Typography>

          {invalidCode && <Typography color="error.main">{sltStr({ key: 'msg-incorrect-code' })}</Typography>}
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <TextField value={validationCode}
              label={sltStr({ key: 'label-verification-code' })}
              autoComplete="off"
              onChange={(e) => {
                setValidationCode(e.target.value);
              }}
              inputProps={{ maxLength: 6 }}
            />
          </FormControl>
        </Box>
        <DialogActions className="mt-4">
          <Box sx={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-start' }}>
            <Button variant="outlined"
              onClick={async () => {
                if (!visit?.id) {
                  return;
                }
                const newMessageID = await sendVerificationCode({
                  variables: {
                    id: visit.id,
                    data: { recipientAddress }
                  }
                });
                if (newMessageID.data?.sendVerificationCode) {
                  setActiveMessageId(newMessageID.data?.sendVerificationCode);
                }
              }}
            >
              {loadingSend && <span className='loading loading-spinner'></span>}
              {sltStr({ key: 'btn-resend' })}
            </Button>
          </Box>
          <Button variant="contained" onClick={async () => {
            if (!visit?.id) {
              return;
            }

            setInvalidCode(false);
            const result = await validateVerificationCode({
              variables: {
                id: visit.id,
                data: {
                  recipientAddress,
                  messageId: activeMessageId,
                  verificationCode: validationCode
                }
              }
            });

            if (result.data?.validateVerificationCode === true) {

              setDataState((st) => {
                return produce(st, (draft) => {
                  if (draft.visit) {
                    draft.visit.verifiedPhone = recipientAddress;
                  }
                });
              });

              onClose(true);
            }
            else {
              setInvalidCode(true);
            }
          }}
            color="primary"
            disabled={size(validationCode) < 6 || loadingSend || loadingValidate}
          >
            {loadingValidate && <span className='loading loading-spinner'></span>}
            {sltStr({ key: 'btn-submit' })}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog >
  );
}

export default memo(ModalValidateSMS);
