import { intersection, isEmpty } from 'lodash';
import { ConditionByInclude, QuestionType, TreeState } from './types';

export function qsIncludes({
  condition,
  treeState,
}: {
  condition: ConditionByInclude;
  question?: QuestionType;
  treeState: TreeState;
}) {
  const { answerMap } = treeState;
  const { keys, qsKey } = condition;

  if (!answerMap) {
    return false;
  }
  const targetMap = answerMap[qsKey];

  const intersect = intersection(targetMap?.answerKeys, keys);

  return !isEmpty(intersect);
}


export function qsIncludesGlobal({
  condition,
  question,
  treeState,
  treeStates,
}: {
  condition: ConditionByInclude;
  question?: QuestionType;
  treeState: TreeState;
  treeStates: TreeState[];
}) {
  // if we have a local tree, and there question we are looking for as a condition is present in that local tree,
  // then ONLY consider the local tree to see if it matches.  This is important because some questions are used
  // repeatedly in multiple trees, and we don't want to have an answer on a different tree drive our answer here
  if (treeState && treeState.tree?.entryQuestionKeys?.find(key => key === condition.qsKey)) {
    return qsIncludes({condition, question, treeState});
  }

  // we didn't have that question in the local tree, so walk the global tree
  return treeStates.some(aTreeState => qsIncludes({condition, question, treeState: aTreeState}))
}
