import {
  OnsetDateFormat,
  OnsetDateHourFormat,
  OnsetDateTimeFormat,
  OnsetMonthFormat,
  OnsetYearFormat,
  PatientDateFormat,
  PatientDateHourFormat,
  PatientDateTimeFormat,
  PatientMonthFormat,
  PatientYearFormat,
  SystemDateTimeSec,
  formatDate,
  parseDateV2,
} from '@cyren/common-lib';
import { subDays, subHours, subMinutes, subMonths, subWeeks, subYears } from 'date-fns';
import produce from 'immer';
import { first, isNaN, isNumber, toNumber } from 'lodash';
import { InputHTMLAttributes, memo, useEffect, useMemo, useState } from 'react';
import Picker from 'react-mobile-picker';
import { useStaticLocales } from '../../../admin/hooks/locales/use-static-locale';
import envs from '../../../common/envs';
import { QuestionType } from '../../../report/types';
import { QuestionProps, UiMetadataChangeHandler } from '../../pa-prop-types';
import { UIMetadataMap } from '../../patient-types';

const units = ['minute', 'hour', 'day', 'week', 'month', 'year'] as const;
type Unit = typeof units[number];

const baseDateDefault = new Date();

function QsInputRelativeOnset(
  props: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
    QuestionProps & {
      onChange: (value?: string) => void;
      question: QuestionType;
      onUiMetadataChange: UiMetadataChangeHandler;
    }
) {
  const {
    // eslint-disable-next-line
    value,
    uiMetadata,
    onUiMetadataChange,
    onChange,
    question,
    // className, error
  } = props;

  const uiMeta = uiMetadata as UIMetadataMap;

  const { questionKey } = question;

  const { sltStr } = useStaticLocales();
  const [formData, setFormData] = useState<{ duration: string; unit: Unit; baseDateStr: string }>({
    duration: uiMeta?.duration || '0',
    unit: (uiMeta?.unit || units[1]) as Unit,  // default to hours unless otherwise specified
    baseDateStr: uiMeta?.baseDateStr || formatDate(new Date(), { formatStr: SystemDateTimeSec }),
  });

  const { baseDateStr, unit } = formData;

  const baseDateObj =
    parseDateV2({
      dateStr: baseDateStr,
      formatStr: SystemDateTimeSec,
    }) || baseDateDefault;

  const [valueDate, printDateFormat, valueFormat] = useMemo(() => {
    // if (questionKey && onUiMetadataChange)
    //   onUiMetadataChange({ uiMetadata: formData, questionKey });

    const dura = toNumber(formData.duration);

    if (!isNumber(dura) || isNaN(dura)) return [null, null, undefined];

    let date = baseDateObj;
    let userDateFormatT = PatientDateTimeFormat;
    let valueFormatT = PatientDateTimeFormat;

    if (unit === 'minute') {
      date = subMinutes(baseDateObj, dura);
      userDateFormatT = PatientDateHourFormat;
      valueFormatT = OnsetDateTimeFormat;
    } else if (unit === 'hour') {
      date = subHours(baseDateObj, dura);
      userDateFormatT = PatientDateHourFormat;
      valueFormatT = OnsetDateHourFormat;
    } else if (unit === 'day') {
      date = subDays(baseDateObj, dura);
      userDateFormatT = PatientDateFormat;
      valueFormatT = OnsetDateFormat;
    } else if (unit === 'week') {
      date = subWeeks(baseDateObj, dura);
      userDateFormatT = PatientDateFormat;
      valueFormatT = OnsetDateFormat;
    } else if (unit === 'month') {
      date = subMonths(baseDateObj, dura);
      userDateFormatT = PatientMonthFormat;
      valueFormatT = OnsetMonthFormat;
    } else if (unit === 'year') {
      date = subYears(baseDateObj, dura);
      userDateFormatT = PatientYearFormat;
      valueFormatT = OnsetYearFormat;
    }

    return [date, userDateFormatT, valueFormatT] as const;
  }, [formData, onUiMetadataChange]);

  const finalPatientDateFormat = printDateFormat;
  const finalValueDateFormat = valueFormat;

  const finalValue = useMemo(() => {
    if (!valueDate) return null;

    const valueStr = formatDate(valueDate, {
      formatStr: finalValueDateFormat,
    });

    return valueStr;
  }, [valueDate, unit, finalValueDateFormat]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!envs.isProd) console.log('validating value >', formData, value, finalValue);

    // prevent initial onChange
    if (formData.duration === '0' || value === finalValue) {
      return;
    }

    // eslint-disable-next-line
    if (!envs.isProd) console.log('updating finalValue', finalValue);

    if (finalValue) {
      onChange(finalValue);
    }
  }, [formData.duration, finalValue]);

  const plural = toNumber(formData?.duration) > 1;

  return (
    <div className='t_QsInputRelativeOnset flex-col gap-3'>
      <div className='flex gap-4'>
        <div className='flex-1 flex-center-y'>
          <input
            type='tel'
            className='input input-bordered w-full'
            value={formData.duration}
            onFocus={(e) => {
              e.target.setSelectionRange(0, e.target.value.length);
            }}
            onChange={(e) => {
              const nState = produce(formData, (dr) => {
                dr.duration = e.target.value;
              });
              setFormData(nState);

              onUiMetadataChange({ uiMetadata: nState, questionKey });
            }}
          />
        </div>
        <div className='flex-1 border select-none'>
          <Picker
            className='px-2'
            value={formData}
            onChange={(p) => {
              onUiMetadataChange({
                uiMetadata: {
                  ...formData,
                  ...p,
                },
                questionKey,
              });

              if (p.unit !== first(units) && formData.duration === '0') {
                setFormData({
                  ...p,
                  duration: '1',
                });
                return;
              }

              setFormData(p);
            }}
            wheelMode='natural'
            height={110}
          >
            <Picker.Column name='unit'>
              {units.map((option) => {
                return (
                  <Picker.Item key={option} value={option}>
                    {({ selected }) => {
                      const label = `${option}${plural ? 's' : ''} ago`;
                      const unitLabel =
                        sltStr({
                          // @ts-ignore
                          key: label,
                          vars: [''],
                        }) || label;

                      return (
                        <div className={selected ? 'font-semibold' : 'opacity-70'}>{unitLabel}</div>
                      );
                    }}
                  </Picker.Item>
                );
              })}
            </Picker.Column>
          </Picker>
        </div>
      </div>

      {valueDate && (
        <div className=''>
          {/* <span>Which is </span> */}
          <span className='font-semibold'>
            {formatDate(valueDate, {
              formatStr: finalPatientDateFormat || PatientDateTimeFormat,
            })}
          </span>
        </div>
      )}
    </div>
  );
}

export default memo(QsInputRelativeOnset);
