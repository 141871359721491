import { Enum_Translation_Locale, QuestionTree } from '@cyren/common-lib';
import { values } from 'lodash';
import { TQuestion, TSurvey } from '../graphql-enhanced';
import { TransConvOptions } from '../patient/pa-prop-types';
import { AnswerValueT } from '../patient/patient-types';
import { HelperFuncKeyT } from './helper-functions';

export type NavParamReport = {
  flowKey?: string;
  orgId?: string;
  treeKey?: string;
};
export type Map = Record<string, string | number | null | undefined | Keys>;
export type ValueMap = Record<string, AnswerValue>;

/* export type AnswerValueT = { */
/*   answerKeys?: Keys; */
/*   answerValues?: Values; */
/*   notesForKeys?: Record<Key, string | undefined | null>; */
/* }; */

export type AnswerValue = AnswerValueT | null | undefined;
export type Key = string;
export type FlowKey = Key;
export type TreeKey = Key;
export type Keys = Key[];
export type Value = string;
export type Values = Value[];
export type EntryQuestionKeys = Keys;
export type QuestionKey = Key;
export type Option = { key: string; label: string };
export type Options = Option[];

export type QuestionAnswerMap = Record<QuestionKey, AnswerValue>;
export type TreeState = {
  treeKey: Key;
  answerMap: QuestionAnswerMap;
  tree: QuestionTreeType | null;
};

export type ModalSxStateType = {
  treeKey: Key;
  answerMap: QuestionAnswerMap;
  tree: QuestionTreeType | null;
};

export type PopupSxStateType = {
  sxKey?: Key;
  answerMap: QuestionAnswerMap;
};

export const Locales = values(Enum_Translation_Locale) as string[];
export type TranslationType = {
  id?: Key | null;
  key?: Key | null;
  value?: Value | null;
  locale?: Enum_Translation_Locale | null | `${Enum_Translation_Locale}`;
};

export type SurveyType = {
  id?: Key;
  shortId: Key;
  title?: string;
  treeKeys: Keys;
};

export type ReportStateType = {
  isDemo?: boolean;
  globalAnswerMap: QuestionAnswerMap;
  treeStates: TreeState[];
  nextTreeKeys: Keys;
  presentIllness: Keys;
  ccKey?: Key;
};

// enhanced GQL types
export type QuestionTreeType = Omit<QuestionTree, 'entryQuestionKeys'> & {
  id?: string | null;
  step?: number;
  entryQuestionKeys: EntryQuestionKeys;
};

export const conditionCategories = ['helper-function', 'value-matching', 'age-range'] as const;
export type ConditionCategory = typeof conditionCategories[number];

export function consoliCondition(condition: ConditionBase) {
  const { category: oCat, type: oType, func } = condition;
  const type = oType || 'include';
  let category: ConditionCategory = oCat || 'value-matching';

  if (func) {
    category = 'helper-function';
  }

  const condToRender = {
    ...condition,
    category,
    type,
  };

  return condToRender as ConditionBase;
}

interface ConditionBase {
  category?: ConditionCategory;
  type?: 'exclude' | 'include';
  keys?: Keys;
  targetTreeKey?: Key;
  func?: HelperFuncKeyT;
}

export interface OptionConditionByFunc extends ConditionBase {
  func: HelperFuncKeyT;
}

export interface ConditionByFunc extends ConditionBase {
  func: HelperFuncKeyT;
}

export interface ConditionByInclude extends ConditionBase {
  qsKey: Key;
}

export function isIncludeCondition(condition: ConditionBase): condition is ConditionByInclude {
  return condition.type === 'include';
}

export type ConditionType =
  | ConditionBase
  | ConditionByFunc
  | ConditionByInclude
  | OptionConditionByFunc;

export type QuestionType = TQuestion;

/**
 * utility function for figuring out follow trees
 */
export type ConditionFunc<T> = (p: {
  question?: QuestionType;
  treeState?: TreeState;
  survey?: TSurvey | null;
  reportState?: ReportStateType;
}) => T;
export type KeyHelper = ConditionFunc<Keys>;
export type ConditionHelper<T> = ConditionFunc<T>;
export type OptionConditionHelper<T> = OptionConditionFunc<T>;
export type OptionConditionFunc<T> = (p: {
  question: QuestionType;
  treeState?: TreeState;
  reportState?: ReportStateType;
}) => T;

export type tFunc = (key?: string) => string | undefined | null;
export type tdFunc = (
  key: string | null | undefined,
  options?: TransConvOptions
) => string | undefined | null;


export type PatientMessageContentType = {
  addOnFormShortId?: string,
  messageSubject?: string,
  messageBody?: string,
  messageHyperlink?: string
  messageSummary?: string
  contentDetails?: string[]
}

export type PatientMessageType = {
  patientVisitId: Key,
  messageType: 'sms' | 'email' | 'manual',
  recipientAddress?: string,
  messageContent?: PatientMessageContentType,
  initiator: 'automatic' | 'user',
  uniqueID: string,
  contentType: 'adhoc' | 'addOnForm' | 'notification' | 'resource',
  opened: boolean
  createdAt: string
}
