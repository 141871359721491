import { isEmpty, pullAll, trim, values } from 'lodash';
import { conditionHelpers } from './helper-functions';
import { qsIncludes } from './match-utils';
import {
  AnswerValue,
  Key,
  Keys,
  QuestionAnswerMap,
  QuestionType,
  TreeState,
  isIncludeCondition,
} from './types';
import { useCsvData } from './use-csv-data';

export function getHasAnswer(answer: AnswerValue) {
  const { answerKeys, answerValues, notesForKeys } = answer || {};
  const hasAnswer =
    !isEmpty(answerKeys) ||
    !isEmpty(answerValues?.map(trim).filter(Boolean)) ||
    !isEmpty(
      values(notesForKeys)
        ?.map((v) => trim(v || ''))
        .filter(Boolean)
    );
  return hasAnswer;
}

export function useTreeHelpers({ treeState }: { treeState?: TreeState | null }) {
  const [, { getQuestionsByKeys }] = useCsvData();

  const questionKeys = treeState?.tree?.entryQuestionKeys || [];
  const questions = getQuestionsByKeys(questionKeys);

  const visibleQs = questions.filter((q) =>
    isQuestionVisible({ question: q, treeKey: treeState?.treeKey })
  );
  const visibleQsKeys = visibleQs.map((q) => q.questionKey);
  const hiddenQsKeys = pullAll([...questionKeys], visibleQsKeys);

  const requiredVisibleQs = visibleQs.filter((q) => q.required);

  const requiredVisibleQsKeys = requiredVisibleQs.map((q) => q.questionKey);

  function getHiddenQsKeys() {
    return hiddenQsKeys;
  }

  function isQuestionAnswered({
    questionKey,
    treeAsMap,
  }: {
    questionKey: Key;
    treeAsMap: QuestionAnswerMap;
  }) {
    const qsAsMap = treeAsMap[questionKey];

    const answered = getHasAnswer(qsAsMap);

    // console.log('answered', questionKey, answered);

    return answered;
  }

  function isQsAllAnswered({ qsKeys }: { qsKeys: Keys }) {
    const treeAsMap = treeState?.answerMap;

    if (isEmpty(treeAsMap)) {
      return false;
    }

    const qsNotAnswered = qsKeys.find((questionKey) => {
      return !isQuestionAnswered({ questionKey, treeAsMap });
    });
    const foundQsNotAnswered = !!qsNotAnswered;

    if (foundQsNotAnswered) {
      return false;
    }

    return true;
  }

  const isAllRequiredQuestionsAnswered = isQsAllAnswered({
    qsKeys: requiredVisibleQsKeys,
  });

  function isQuestionVisible({ question, treeKey }: { question: QuestionType; treeKey?: Key }) {
    if (!treeState) return true;

    const { enabled, showConditions } = question;

    if (enabled === false) return false;

    if (!isEmpty(showConditions)) {
      const showConditionMet = showConditions?.some((condition) => {
        if (condition?.targetTreeKey != null && condition?.targetTreeKey !== treeKey) {
          return true;
        }

        if ('func' in condition && condition.func) {
          const func = conditionHelpers?.[condition.func];

          return func({ question, treeState });
        }

        if ('type' in condition && isIncludeCondition(condition)) {
          return qsIncludes({ condition, question, treeState });
        }

        return false;
      });

      if (!showConditionMet) {
        return false;
      }
    }

    return true;
  }

  return [
    { isValid: isAllRequiredQuestionsAnswered },
    {
      isQuestionVisible,
      getHiddenQsKeys,
    },
  ] as const;
}
