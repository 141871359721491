import { differenceInMonths, differenceInYears, format, formatDistanceStrict, isValid, parse, parseISO, } from 'date-fns';
import { first, size, split, toInteger } from 'lodash';
export const TimeFormat = 'hh:mm aa';
export const DateFormat = 'yyyy-MM-dd';
export const DateTimeSecFormat = 'MM-dd-yyyy HH:mm:ss';
export const DateTimeFormat = 'MM-dd-yyyy HH:mm';
export const DateProviderFormat = 'MM-dd-yyyy';
export const DateTimeProviderFormat = 'MM-dd-yyyy HH:mm:ss';
export const DateTimeProviderFormat2 = 'MM-dd-yyyy HH:mm';
export const SystemDateTimeSec = 'yyyy-MM-dd HH:mm:ss';
export const SystemDateTimeMin = 'yyyy-MM-dd HH:mm';
export const SystemDateTime = 'yyyy-MM-dd HH';
export const SystemDate = 'yyyy-MM-dd';
export const PatientDateTimeFormat = 'MMMM do yyyy EEEE hh:mm aa';
export const PatientDateHourFormat = 'MMMM do yyyy EEEE hh aa';
export const PatientDateFormat = 'MMMM do yyyy EEEE';
export const PatientMonthFormat = 'MMMM yyyy';
export const PatientYearFormat = 'yyyy';
export const OnsetDateTimeFormat = 'yyyy-MM-dd HH:mm';
export const OnsetDateHourFormat = 'yyyy-MM-dd HH';
export const OnsetDateFormat = 'yyyy-MM-dd';
export const OnsetMonthFormat = 'yyyy-MM';
export const OnsetYearFormat = 'yyyy';
export const PatientDobFormat = 'MM/dd/yyyy';
export const NoYearDateTimeFormat = 'MM-dd HH:mm';
export function dateDistance(dateStr) {
    if (!dateStr) {
        return null;
    }
    return formatDistanceStrict(new Date(dateStr), new Date(), {
        addSuffix: true,
    });
}
export function parseDate(dateStr, formatStr = 'yyyy-MM-dd HH:mm:ss', defaultValue = new Date()) {
    try {
        if (!dateStr) {
            return defaultValue;
        }
        const parsed = parse(dateStr, formatStr, defaultValue);
        if (isValid(parsed)) {
            return parsed;
        }
        return defaultValue;
    }
    catch (error) {
        // eslint-disable-next-line
        console.error('ignore parsing error', error);
    }
    return defaultValue;
}
export function parseDateV2({ dateStr, formatStr, defaultValue = null, }) {
    try {
        if (!dateStr) {
            return defaultValue;
        }
        if (formatStr) {
            const parsed = parse(dateStr, formatStr, 0);
            // console.log('parsed', parsed);
            if (isValid(parsed)) {
                return parsed;
            }
        }
        else {
            // auto detect
            const strLen = size(dateStr);
            const longFormat = strLen > 11;
            const inferedFormat = longFormat ? SystemDateTimeSec : SystemDate;
            const parsed = parse(dateStr, inferedFormat, 0);
            if (isValid(parsed)) {
                return parsed;
            }
        }
        return null;
    }
    catch (error) {
        // eslint-disable-next-line
        console.error('ignore parsing error', error);
    }
    return null;
}
export function systemDateToClientDate({ dateStr }) {
    try {
        if (!dateStr) {
            return null;
        }
        // auto detect
        const strLen = size(dateStr);
        // long format > 'MM-dd-yyyy HH:mm:ss'
        // short format > 'MM-dd-yyyy'
        const isLongFormat = strLen > 11;
        const inferedFormatIn = isLongFormat ? SystemDateTimeSec : SystemDate;
        const inferedFormatOut = isLongFormat ? DateTimeProviderFormat : DateProviderFormat;
        const parsed = parse(dateStr, inferedFormatIn, 0);
        if (isValid(parsed)) {
            return formatDate(parsed, { formatStr: inferedFormatOut });
        }
    }
    catch (error) {
        // eslint-disable-next-line
        console.error('ignore parsing error', error);
    }
    return null;
}
export function formatDate(date, { formatStr = DateTimeSecFormat } = {}) {
    if (date) {
        try {
            const d = format(date, formatStr);
            return d;
        }
        catch (error) {
            console.error('error', error);
        }
    }
    return '';
}
export function dateStrToAge(dateStr, options) {
    const { dateFrom, inputFormat } = options || {};
    const date = parseDate(first(split(dateStr, ' ')), inputFormat || DateProviderFormat);
    const age = differenceInYears(dateFrom || new Date(), date);
    return age;
}
export function dateStrToAgeMonths(dateStr, options) {
    const { dateFrom, inputFormat } = options || {};
    const date = parseDate(first(split(dateStr, ' ')), inputFormat || DateProviderFormat);
    const age = differenceInMonths(dateFrom || new Date(), date);
    return age;
}
export function dateStrToAgeWithBabyFormat(dateStr, options) {
    const { dateFrom, inputFormat } = options || {};
    const date = parseDate(first(split(dateStr, ' ')), inputFormat || DateProviderFormat);
    const age = differenceInYears(dateFrom || new Date(), date);
    if (age < 2) {
        return `${differenceInMonths(dateFrom || new Date(), date)}mo`;
    }
    return age;
}
function pad(num, size) {
    let numStr = num.toString();
    while (numStr.length < size)
        numStr = '0' + numStr;
    return numStr;
}
export function distDateFormat(date) {
    if (!date)
        return '-';
    try {
        const nowInSecs = new Date().getTime();
        const distInMins = toInteger((nowInSecs - date.getTime()) / 60 / 1000);
        const distMins = distInMins % 60;
        const distHrs = toInteger(distInMins / 60);
        return `${pad(distHrs, 2)} ${distHrs > 1 ? 'hrs' : 'hr'} ${pad(distMins, 2)} ${distMins > 1 ? 'mins' : 'min'}`;
    }
    catch (error) { }
    return '-';
}
export function beDateToDateObj(dateStr) {
    if (!dateStr)
        return null;
    try {
        return parseISO(dateStr);
    }
    catch (error) {
        console.error(error);
    }
    return null;
}
export function parseDateAndTime(dateValue) {
    const parsedDateValue = dateValue?.toString().substring(0, 10);
    const initDateValue = size(parsedDateValue) === 10 ? parsedDateValue : '';
    const parsedTimeValue = dateValue?.toString().substring(11);
    const initTimeValue = size(parsedTimeValue) >= 5 ? parsedTimeValue?.substring(0, 5) : '';
    return [initDateValue, initTimeValue];
}
export function getGreetings() {
    const isMorning = format(new Date(), 'a') === 'AM';
    return `Good ${isMorning ? 'morning' : 'afternoon'}`;
}
export function formatDTsToReportDateFormat(dateValue) {
    if (!dateValue)
        return null;
    // export const SystemDateTimeSec = 'yyyy-MM-dd HH:mm:ss';
    if (size(dateValue) > 18) {
        const dateObj = parseDateV2({
            dateStr: dateValue,
            formatStr: SystemDateTimeSec,
            defaultValue: null,
        });
        return formatDate(dateObj, { formatStr: 'yyyy-MM-dd h:mm:ss a' });
    }
    // export const SystemDateTimeMin = 'yyyy-MM-dd HH:mm';
    if (size(dateValue) > 14) {
        const dateObj = parseDateV2({
            dateStr: dateValue,
            formatStr: SystemDateTimeMin,
            defaultValue: null,
        });
        return formatDate(dateObj, { formatStr: 'yyyy-MM-dd h:mm a' });
    }
    // export const SystemDateTime = 'yyyy-MM-dd HH';
    if (size(dateValue) > 11) {
        const dateObj = parseDateV2({
            dateStr: dateValue,
            formatStr: SystemDateTime,
            defaultValue: null,
        });
        return formatDate(dateObj, { formatStr: 'yyyy-MM-dd h a' });
    }
    // export const SystemDateTime = 'yyyy-MM-dd';
    if (size(dateValue) > 9) {
        const dateObj = parseDateV2({
            dateStr: dateValue,
            formatStr: SystemDate,
            defaultValue: null,
        });
        return formatDate(dateObj, { formatStr: 'yyyy-MM-dd' });
    }
    return null;
}
