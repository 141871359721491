import {
  DeploymentFragment,
  Enum_Question_Type,
  PatientVisitFragment,
  QuestionFragment,
  QuestionTreeFragment,
  QuestionTreeTemplateFragment,
  StaffFragment,
  StaffMemberFragment,
  SurveyFragment,
  TranslationFragment,
  UserMemberFragment,
  notNull,
} from '@cyren/common-lib';
import { isArray, omit } from 'lodash';
import { decryptVisit } from '../../admin/report/visit-encrypt';
import {
  ClientModel,
  TDeployment,
  TPatientVisit,
  TStaff,
  TStaffMember,
  TTranslation,
  TUser,
} from '../../graphql-enhanced';
import {
  ConditionType,
  Key,
  Keys,
  QsAnswerOptionsMap,
  QsOptions,
  QuestionTreeTemplateType,
  QuestionTreeType,
  QuestionType,
  SurveyType,
} from '../../patient/patient-types';
import { ReportConfigStateT } from '../../survey/template/template-types';

export function convToRender<T extends { id?: string | null; attributes?: T['attributes'] }>(
  data?: T | null
): ClientModel<T> | null {
  if (data == null) return null;

  return {
    id: data?.id,
    ...data?.attributes,
  };
}

// eslint-disable-next-line
export function typeKeys(keys?: any) {
  if (keys == null) return [] as Keys;
  return keys as Keys;
}

export function convVisit(obj?: PatientVisitFragment | null) {
  let result = convToRender(obj);
  if (result == null) return result;

  result = decryptVisit(result) as ClientModel<PatientVisitFragment>;

  return {
    ...result,
  } as TPatientVisit;
}

export function convVisits(list?: PatientVisitFragment[] | null) {
  const items = list?.map(convVisit).filter(notNull) || [];
  return items;
}

export function convSurvey(obj?: SurveyFragment | null) {
  const result = convToRender(obj);
  return {
    ...result,
    shortId: result?.short_id,
    treeKeys: result?.questionTreeKeys,
    organization: convToRender(result?.organization?.data),
    deployment: convDeployment(result?.deployment?.data),
    metadata: result?.metadata || {
      frontPageKeys: [],
      finishedPageKeys: [],
    },
  } as SurveyType;
}

export function convSurveys(list?: SurveyFragment[] | null) {
  const surveys = list?.map(convSurvey).filter(notNull) || [];
  return surveys;
}

export function convMemberUser(obj?: UserMemberFragment | null) {
  const result = convToRender(obj) as TUser;
  return result;
}

export function convStaff(obj?: StaffFragment | null) {
  const result = convToRender(obj);
  return {
    ...result,
    organization: convToRender(result?.organization?.data),
  } as TStaff | null;
}

export function convStaffMember(obj?: StaffMemberFragment | null) {
  const result = convToRender(obj);
  return {
    ...result,
    user: convMemberUser(result?.user?.data),
  } as TStaffMember | null;
}

export function convQsOptions(obj?: QuestionType): QsOptions {
  const options = {
    ...obj?.typeOptions,
  };

  if (obj?.type === Enum_Question_Type.Range || obj?.type === Enum_Question_Type.Number) {
    options.min = options?.min || obj?.answerKeys?.[0];
    options.max = options?.max || obj?.answerKeys?.[1];
    obj.answerKeys = [];
  }

  if (obj?.type === Enum_Question_Type.Text || obj?.type === Enum_Question_Type.Textarea) {
    options.max = options?.max || (obj?.maxLength == null ? undefined : `${obj?.maxLength}`);
    delete obj.maxLength;
  }

  if (obj?.type === Enum_Question_Type.Select) {
    options.min =
      options?.min || (obj.minSelectedOptions == null ? undefined : `${obj.minSelectedOptions}`);
    options.max =
      options?.max || (obj.maxSelectedOptions == null ? undefined : `${obj.maxSelectedOptions}`);

    if (options.multi == null) {
      options.multi = !(options.max === '1');
    }
  }

  return options;
}

export function convQsShowConditions(obj?: QuestionType): ConditionType[] {
  let nConditions = (obj?.showConditions || []) as ConditionType[];

  nConditions = nConditions.map((cond) => {
    const { targetTreeKey } = cond;

    if (targetTreeKey != null) {
      if (!cond.targetTreeKeys) cond.targetTreeKeys = [];

      if (!cond.targetTreeKeys.includes(targetTreeKey)) cond.targetTreeKeys?.push(targetTreeKey);
    }
    return cond;
  });

  return nConditions;
}

export function convQsAnswerOptions(obj?: QuestionType): QsAnswerOptionsMap {
  const optionMap: QsAnswerOptionsMap = {
    ...obj?.answerOptionsMap,
  };

  obj?.extNoteRequiredKeys?.forEach((nrk: Key) => {
    optionMap[nrk] = {
      ...optionMap[nrk],
      requireNote: true,
    };
  });

  return optionMap;
}

export function convQs(obj?: QuestionFragment | null): QuestionType | undefined {
  const result = convToRender(obj) as QuestionType | undefined;
  if (result) {
    result.typeOptions = convQsOptions(result);
    result.answerOptionsMap = convQsAnswerOptions(result);
    result.showConditions = convQsShowConditions(result);

    delete result.maxLength;
    delete result.maxSelectedOptions;
    delete result.minSelectedOptions;
  }

  return result;
}

export function convTree(obj?: QuestionTreeFragment | null) {
  const result = convToRender(obj) as QuestionTreeType;
  return result;
}

export function convTreeTemplate(obj?: QuestionTreeTemplateFragment | null) {
  const result = convToRender(obj) as QuestionTreeTemplateType;

  if (result != null) result.template = (result?.template || {}) as ReportConfigStateT;

  return result;
}

export function convTrans(obj?: TranslationFragment | null) {
  const result = convToRender(obj) as TTranslation;
  return result;
}

export function convDeployment(obj?: DeploymentFragment | null) {
  const result = convToRender(obj) as TDeployment | null;
  return result;
}

export type MutParams<T> = Omit<T, 'id' | '__typename' | 'createdAt' | 'updatedAt'>;
export type CreateParams<T> = Omit<T, '__typename' | 'createdAt' | 'updatedAt'>;

// TODO deprecate this in favor of convMutParams
export function convParams<
  T extends { id?: string | null; __typename?: string } | null | undefined
>(obj?: T) {
  if (obj == null) return {} as Partial<T>;

  const data = omit(obj, ['id', '__typename', 'createdAt', 'updatedAt', 'images']) as MutParams<T>;

  // @ts-ignore
  const imagesData = obj?.images?.data;

  if (isArray(imagesData)) {
    // @ts-ignore
    data.images = imagesData?.map((i) => i?.id).filter(notNull);
  }
  return data;
}

// TODO rename to convUpdateParams
export function convMutParams<T extends { id?: string | null; __typename?: string }>(obj?: T) {
  // TODO add '_id' and check if it would regress
  const pa = (omit(obj, ['__typename', 'createdAt', 'updatedAt']) || {}) as MutParams<T>;
  return pa || {};
}

export function convUpdateParams<T extends { id?: string | null; __typename?: string }>(obj?: T) {
  const pa = (omit(obj, ['id', '__typename', 'createdAt', 'updatedAt']) || {}) as MutParams<T>;
  return pa || {};
}

export function convCreateParams<T extends { id?: string | null; __typename?: string }>(obj?: T) {
  const pa = (omit(obj, ['__typename', 'createdAt', 'updatedAt']) || {}) as CreateParams<T>;
  return pa || {};
}
