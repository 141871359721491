import produce from 'immer';
import { intersection, uniq } from 'lodash';
import { memo, useState } from 'react';

import classNames from 'classnames';
import {
  getMutualExclusiveAnswerKeys,
  getRequireNoteAnswerKeys,
} from '../../../../utils/models/question';
import { useTranslations } from '../../../hooks/use-translations';
import { BodySelectorProps } from '../../../pa-prop-types';
import { Keys } from '../../../patient-types';

import oEyeLt from '../assets/head/o-eye-lt.svg';
import oEyeRt from '../assets/head/o-eye-rt.svg';
import oHeadBack from '../assets/head/o-head-back.svg';
import oHeadBand from '../assets/head/o-head-band.svg';
import oHeadFront from '../assets/head/o-head-front.svg';
import oHeadSideLt from '../assets/head/o-head-side-lt.svg';
import oHeadSideRt from '../assets/head/o-head-side-rt.svg';
import oHeadTempleLt from '../assets/head/o-head-temple-lt.svg';
import oHeadTempleRt from '../assets/head/o-head-temple-rt.svg';
import oHeadTop from '../assets/head/o-head-top.svg';
import oHeadWhole from '../assets/head/o-head-whole.svg';
import oNeckBack from '../assets/head/o-neck-back.svg';
import oNeckLt from '../assets/head/o-neck-lt.svg';
import oNeckRt from '../assets/head/o-neck-rt.svg';
import oOther from '../assets/head/o-other.svg';
import oSinus from '../assets/head/o-sinus.svg';

const headImageMap: { [key: string]: string } = {
  'o-head-whole': oHeadWhole,
  'o-head-top': oHeadTop,
  'o-head-front': oHeadFront,
  'o-head-back': oHeadBack,
  'o-sinus': oSinus,
  'o-head-band': oHeadBand,
  'o-head-side-rt': oHeadSideRt,
  'o-head-temple-rt': oHeadTempleRt,
  'o-eye-rt': oEyeRt,
  'o-head-side-lt': oHeadSideLt,
  'o-head-temple-lt': oHeadTempleLt,
  'o-eye-lt': oEyeLt,
  'o-neck-lt': oNeckLt,
  'o-neck-rt': oNeckRt,
  'o-neck-back': oNeckBack,
  'o-other': oOther,
};

function BodyPartSelectorHead(props: BodySelectorProps) {
  const { standalone, question, onValueChange, answerValue: answerValueInit, locale } = props;
  const { answerKeys } = question || {};

  const [, { t }] = useTranslations({ locale });

  const [answerValue, setAnswerValue] = useState(answerValueInit || {});
  const [noteKeys, setNoteKeys] = useState<string[]>([]);

  const mutExcAnswerKeys = getMutualExclusiveAnswerKeys({ question });
  const requireNoteKeys = getRequireNoteAnswerKeys({ question });

  const selectedKeys = answerValue.answerKeys || [];

  function handleClick({ id }: { id: string }) {
    // eslint-disable-next-line
    console.log('id', id);

    const selected = selectedKeys.includes(id);
    const isMutExcl = mutExcAnswerKeys.includes(id);

    let nKeys: Keys = [...selectedKeys];

    if (isMutExcl) {
      // ONLY INCLUDE MUT KEY
      if (selected) {
        nKeys = [];
      } else {
        nKeys = [id];
      }
    } else {
      // EXCLUDE ALL MUT KEYS
      nKeys = nKeys.filter((k) => !mutExcAnswerKeys.includes(k));
      // eslint-disable-next-line no-lonely-if
      if (selected) {
        nKeys = nKeys.filter((i) => i !== id);
      } else {
        nKeys = uniq([...nKeys, id]);
      }
    }

    setNoteKeys(intersection(nKeys, requireNoteKeys));

    const nValue = produce(answerValue, (dr) => {
      dr.answerKeys = nKeys;
    });

    setAnswerValue(nValue);
    onValueChange(nValue);
  }

  return (
    <div className='t_BodyPartSelectorHead flex-col'>
      <div className={classNames('flex-col', !standalone && 'p-4')}>
        <div className='relative max-w-full pt-2'>
          <div className='pt-2 pb-3 grid grid-cols-3 gap-x-2 gap-y-10'>
            {answerKeys?.map((answerKey) => {
              // eslint-disable-next-line
              const img = headImageMap?.[answerKey];
              const selected = selectedKeys.includes(answerKey);

              return (
                <div key={answerKey} className='flex-center items-start'>
                  <div className='flex-col gap-4'>
                    <div className='flex-center'>
                      <img src={img} />
                    </div>
                    <div className='flex-center flex-col gap-2'>
                      <input
                        type='checkbox'
                        value={answerKey}
                        checked={selected}
                        className='checkbox checkbox-lg checkbox-primary'
                        onChange={() => {
                          handleClick({ id: answerKey });
                        }}
                      />
                      <label>{t(answerKey)}</label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {noteKeys.map((noteKey) => {
          return (
            <div key={noteKey} className='flex-none flex pt-3 gap-2'>
              <label className='mui-input flex-1'>
                <input
                  placeholder=''
                  value={answerValue?.notesForKeys?.[noteKey] || ''}
                  onChange={(e) => {
                    const nValue = produce(answerValue, (dr) => {
                      if (!dr.notesForKeys) dr.notesForKeys = {};
                      dr.notesForKeys[noteKey] = e.target.value;
                    });

                    setAnswerValue(nValue);
                    onValueChange(nValue);
                  }}
                />
                <span>DESCRIBE {t(noteKey)}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(BodyPartSelectorHead);
