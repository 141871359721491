import {
  Enum_Translation_Locale,
  Enum_Translation_Type,
  InputMaybe,
  WatchQueryFetchPolicy,
  isNotEmpty,
  notNull,
  useTranslationsLazyQuery,
} from '@cyren/common-lib';
import produce from 'immer';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AppStateCookie } from '../app/use-app-state';
import { convTrans } from '../gql-tools/utils/convert';
import { isEqualTrans } from '../models/translation';
import { Keys } from '../patient/patient-types';
import { PaDataRepoState } from './use-data';

export function usePatientTransData({
  keys,
  locale,
}: { locale?: Enum_Translation_Locale; keys?: InputMaybe<Keys> } = {}) {
  const [appStateCookie] = useRecoilState(AppStateCookie);
  const localeKey = locale || appStateCookie?.localeKey || 'english';

  const [dataState, setDataState] = useRecoilState(PaDataRepoState);
  const { survey, translations } = dataState;

  const keysNotFound = keys
    ?.filter((key) => {
      const found = translations?.find((tr) => tr.key === key && tr.locale === localeKey);
      if (!found) return key;
      return null;
    })
    .filter(notNull)
    .sort();

  const [fetchTransGql, { data: dataTrans, loading: loadingTrans }] = useTranslationsLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const list = data?.translations?.data?.map(convTrans);

      setDataState((st) => {
        return produce(st, (draft) => {
          list?.forEach((item) => {
            const idx = draft.translations.findIndex((t) =>
              isEqualTrans({ trans1: t, trans2: item })
            );

            if (idx > -1) {
              draft.translations[idx] = item;
            } else {
              draft.translations.push(item);
            }
          });
        });
      });
    },
  });

  const fetchTrans = async ({ fetchPolicy }: { fetchPolicy?: WatchQueryFetchPolicy } = {}) => {
    if (survey?.id == null || isEmpty(keysNotFound)) {
      return;
    }

    fetchTransGql({
      fetchPolicy,
      variables: {
        pagination: {
          limit: 10000,
        },
        filters: {
          type: {
            eq: Enum_Translation_Type.Patient,
          },
          locale: {
            eq: localeKey,
          },
          key: {
            in: keysNotFound,
          },
          survey_id: {
            eq: parseInt(survey?.id, 10),
          },
        },
      },
    });
  };

  // selected treeId
  useEffect(() => {
    fetchTrans();
  }, [localeKey, `${keysNotFound}`, survey?.id]);

  return [
    {
      dataState,
      dataTrans,
      loadingTrans: loadingTrans || (isNotEmpty(keysNotFound) && !dataTrans?.translations),
    },
    {
      fetchTrans,
    },
  ] as const;
}
