import {
  Enum_Translation_Locale,
  isNotEmpty,
  notNull,
  usePaQuestionsLazyQuery,
} from '@cyren/common-lib';
import produce from 'immer';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { convQs, convTrans } from '../../gql-tools/utils/convert';
import { isEqualTrans } from '../../models/translation';
import { PaDataRepoState } from '../../report/use-data';
import { onError } from '../../utils/apollo-utils';
import { Keys } from '../patient-types';

export function usePaQuestions({ keys, locale }: { keys: Keys; locale: Enum_Translation_Locale }) {
  const [dataState, setDataState] = useRecoilState(PaDataRepoState);
  const { questions, survey } = dataState;

  const surveyId = survey?.id;

  const keysNotFound = (keys || []).filter(
    (key) => !questions.find((tr) => tr.questionKey === key)
  );

  const [loadQssAndTranss, { loading }] = usePaQuestionsLazyQuery({
    fetchPolicy: 'network-only',
    onError,
    onCompleted: (data) => {
      const transs = data?.paQuestions?.translations?.data?.map(convTrans);
      const qss = data?.paQuestions?.questions?.data?.map(convQs).filter(notNull);

      setDataState((st) => {
        return produce(st, (draft) => {
          transs?.forEach((item) => {
            const idx = draft.translations.findIndex((t) =>
              isEqualTrans({ trans1: t, trans2: item })
            );

            if (idx > -1) {
              draft.translations[idx] = item;
            } else {
              draft.translations.push(item);
            }
          });
          qss?.forEach((item) => {
            const idx = draft.questions.findIndex((t) => t.id === item?.id);

            if (idx > -1) {
              draft.questions[idx] = item;
            } else {
              draft.questions.push(item);
            }
          });
        });
      });
    },
  });

  useEffect(() => {
    if (!surveyId) return;
    if (!isNotEmpty(keysNotFound)) return;

    loadQssAndTranss({
      variables: {
        input: {
          keys: keysNotFound,
          locale,
          survey: surveyId,
        },
      },
    });
  }, [`${keysNotFound}`]);

  return [{ fetching: loading }] as const;
}
