import produce from 'immer';
import { useRecoilValue } from 'recoil';
import { PaReportState } from '../patient/hooks/use-report';
import { conditionHelpers } from './helper-functions';
import { QuestionType } from './types';
import { PaDataRepoState } from './use-data';

export function useQuestionHelpers({
  question: questionRaw,
  isDisplayMode,
}: {
  question?: QuestionType;
  isDisplayMode?: boolean;
}) {
  const reportState = useRecoilValue(PaReportState);
  const { survey } = useRecoilValue(PaDataRepoState);
  let question = questionRaw;
  const { optionConditions } = questionRaw || {};

  if (isDisplayMode || !question || !questionRaw || !reportState) {
    return [{ question }] as const;
  }

  optionConditions?.forEach((condition) => {
    let matched = false;
    if (condition?.func != null) {
      const func = conditionHelpers?.[condition?.func];

      matched = func({ question: questionRaw, reportState, survey });
    }

    if (
      // when matched & exclude keys
      (matched === true && condition.type === 'exclude') ||
      // when not-matched & include keys
      (matched === false && condition.type === 'include')
    )
      question =
        produce(question, (draft) => {
          if (!draft) return question;

          // remove exclude keys
          draft.answerKeys = draft.answerKeys?.filter((answerKey) => {
            return !condition?.keys?.includes(answerKey);
          });

          return draft;
        }) || question;
  });

  // return true;
  return [{ question }] as const;
}
