/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Enum_Translation_Locale, notNull } from '@cyren/common-lib';
import classNames from 'classnames';
import produce from 'immer';
import { first, includes, isEmpty, uniq, values } from 'lodash';
import { InputHTMLAttributes, memo, useEffect, useState } from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import LoadingAnim from '../../../components/LoadingAnim';
import { useData } from '../../../report/use-data';
import { usePatientTreesData } from '../../../report/use-patient-tree-data copy';
import { getHasAnswer } from '../../../report/use-tree-helpers';
import { getMutualExclusiveAnswerKeys } from '../../../utils/models/question';
import { useReportState } from '../../hooks/use-report';
import { useTranslations } from '../../hooks/use-translations';
import { ModeT } from '../../pa-prop-types';
import { Key, QuestionType } from '../../patient-types';
import ModalSxCollector from '../ModalSxCollector';
import { ModalSxState } from '../use-sx-modal-answers';

function QsSxCollector(
  props: InputHTMLAttributes<HTMLInputElement> & {
    locale: Enum_Translation_Locale;
    question: QuestionType;
    treeKey?: Key;
    mode?: ModeT;
    // onConfirmed: (modalSxState: ModalSxStateType) => void;
  }
) {
  const { locale, mode, treeKey, question } = props;
  const [, { t }] = useTranslations({ locale });
  const [, { getTreeByKey }] = useData();

  const { answerKeys, questionKey } = question;
  const isDisplayMode = mode === 'display';

  const [stagedSx, setStagedSx] = useState<Key | null>(null);

  const [{ reportState }, { setReportState }] = useReportState({
    treeKey: stagedSx || '',
  });

  const treeState = reportState.treeStates.find((ts) => ts.treeKey === treeKey);
  const mutExcKeys = getMutualExclusiveAnswerKeys({ question });
  const [modalSxState, setModalSxState] = useRecoilState(ModalSxState(treeKey));

  // console.log('treeState', treeState);
  // console.log('reportState', reportState.treeStates);
  /* console.log('modalSxState', modalSxState); */
  /* console.log('reportState', reportState); */

  const selectedSxKeys = uniq([
    ...(modalSxState?.answerMap[question.questionKey]?.answerKeys || []),
    ...(treeState?.answerMap[question.questionKey]?.answerKeys || []),
  ]);

  const [
    {
      loadingTrees,
      dataState: { trees },
    },
  ] = usePatientTreesData({
    keys: answerKeys,
  });

  useEffect(() => {
    // find sx trees already filled
    const sxTreesKeysWithAnswers =
      reportState?.treeStates
        ?.map((ts) => {
          const savedPreviously = question.answerKeys?.includes(ts.treeKey);
          if (savedPreviously) {
            const hasValues = getHasAnswer(first(values(ts.answerMap)));
            if (hasValues) {
              return ts.treeKey;
            }
          }
          return null;
        })
        ?.filter(notNull) || [];

    if (modalSxState) {
      const nState = {
        ...modalSxState,
        answerMap: {
          ...modalSxState.answerMap,
          [questionKey]: {
            answerKeys: sxTreesKeysWithAnswers,
          },
        },
      };

      setModalSxState(nState);
    } else {
      const tree = trees.find((tr) => tr.treeKey === treeKey);

      if (tree && treeKey) {
        setModalSxState({
          answerMap: {},
          tree,
          treeKey,
        });
      }
    }
  }, []);

  if (loadingTrees) {
    return <LoadingAnim />;
  }

  return (
    <div className='t_QsSxCollector flex-1'>
      {stagedSx && (
        <ModalSxCollector
          locale={locale}
          sxKey={stagedSx}
          onClose={() => {
            setStagedSx(null);
          }}
          onInterimUpdate={() => {}}
          onSave={(nModalSxState) => {
            // eslint-disable-next-line
            console.log('nModalSxState', nModalSxState);

            // TODO make it dynamic
            const sxTreeStateIdx = reportState.treeStates.findIndex(
              (ts) => ts.treeKey === stagedSx
            );

            const mainTreeStateIdx = reportState.treeStates.findIndex(
              (ts) => ts.treeKey === treeKey
            );

            const mainTreeState = reportState.treeStates.find((ts) => ts.treeKey === treeKey);

            let nextSxKeys = uniq([...selectedSxKeys, stagedSx]);

            if (!isEmpty(mutExcKeys)) {
              nextSxKeys = nextSxKeys.filter((k) => !mutExcKeys.includes(k));
            }

            const nReportState = produce(reportState, (draft) => {
              // update question state in main tree
              if (mainTreeState?.treeKey && mainTreeStateIdx !== -1) {
                const nTreeState = {
                  ...mainTreeState,
                  answerMap: {
                    ...mainTreeState?.answerMap,
                    [questionKey]: {
                      answerKeys: nextSxKeys,
                    },
                  },
                };

                // console.log('nTreeState', nTreeState);
                draft.treeStates[mainTreeStateIdx] = nTreeState;
              }

              if (sxTreeStateIdx !== -1) {
                // update detail of collapsable
                // sx tree state found
                draft.treeStates[sxTreeStateIdx] = nModalSxState;
              } else {
                // sxtree not found
                draft.treeStates.push(nModalSxState);
              }
            });

            setReportState(nReportState);

            if (modalSxState) {
              const nState = {
                ...modalSxState,
                answerMap: {
                  ...modalSxState.answerMap,
                  [questionKey]: {
                    answerKeys: nextSxKeys,
                  },
                },
              };

              setModalSxState(nState);
            }

            setStagedSx(null);
          }}
        />
      )}

      <div className='flex-col gap-2'>
        {answerKeys?.map((optionSxKey) => {
          const sxTree = getTreeByKey(optionSxKey);

          // simple option checkbox (not sx tree option)
          if (!sxTree) {
            const checked = includes(selectedSxKeys, optionSxKey);
            const isExclMut = mutExcKeys.includes(optionSxKey);
            return (
              <div key={optionSxKey} className='form-control'>
                <label
                  className={classNames(
                    'label cursor-pointer gap-2 pl-5 pr-4 py-3 border rounded-lg flex',
                    checked && 'bg-primary bg-opacity-5'
                  )}
                >
                  <span className={''}>{t(optionSxKey)}</span>
                  <input
                    type='checkbox'
                    checked={checked}
                    className='checkbox checkbox-lg checkbox-primary rounded'
                    value={optionSxKey}
                    onChange={(e) => {
                      const nChecked = e.target.checked;

                      if (nChecked && isExclMut) {
                        setReportState((st) =>
                          produce(st, (dr) => {
                            dr.treeStates = dr.treeStates.filter((ts) => {
                              return !answerKeys.includes(ts.treeKey);
                            });
                          })
                        );
                      }

                      setModalSxState((st) => {
                        const nState = produce(st, (dr) => {
                          if (!dr) return;

                          if (dr?.answerMap == null) dr.answerMap = {};
                          if (dr?.answerMap[questionKey] == null) dr.answerMap[questionKey] = {};

                          if (dr.answerMap[questionKey]) {
                            if (nChecked) {
                              if (isExclMut) {
                                dr.answerMap[questionKey]!.answerKeys = [optionSxKey];
                              } else {
                                dr.answerMap[questionKey]!.answerKeys = [
                                  ...(dr.answerMap[questionKey]!.answerKeys || []),
                                  optionSxKey,
                                ];
                              }
                            } else {
                              const nKeys = dr.answerMap[questionKey]!.answerKeys || [];
                              dr.answerMap[questionKey]!.answerKeys = nKeys.filter(
                                (k) => k !== optionSxKey
                              );
                            }
                          }
                        });

                        // console.log('nState', nState);

                        return nState;
                      });
                    }}
                  />
                </label>
              </div>
            );
          }

          const sxState = reportState.treeStates?.find((ts) => {
            return ts.treeKey === optionSxKey;
          });

          const sxSelected = includes(selectedSxKeys, optionSxKey);
          const keysAndValues = values(sxState?.answerMap);
          const checked = !isDisplayMode && sxSelected && !isEmpty(keysAndValues);

          return (
            <div
              key={optionSxKey}
              className={classNames(
                'pl-5 pr-2 py-1 border rounded-lg flex cursor-pointer',
                checked && 'bg-primary bg-opacity-5'
              )}
              onClick={() => {
                setStagedSx(optionSxKey);
              }}
            >
              <div className='flex-1 flex-center-y'>{t(optionSxKey)}</div>

              <div className={classNames('flex-center py-2', checked && 'hidden')}>
                <input
                  type='checkbox'
                  className='checkbox checkbox-lg checkbox-primary rounded mr-2'
                  checked={false}
                  onChange={() => {}}
                />
              </div>

              <div className={classNames('flex gap-0 flex-nowrap', !checked && 'hidden')}>
                <button
                  className={classNames('btn btn-ghost px-2')}
                  onClick={() => {
                    setStagedSx(sxTree.treeKey);
                  }}
                >
                  <BiPencil size={22} className='cursor-pointer' />
                </button>
                <button
                  className='btn btn-ghost px-2'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const yes = window.confirm('Remove your answers?');
                    if (!yes) return;

                    // delete
                    const sxTreeStateIdx = reportState.treeStates.findIndex(
                      (ts) => ts.treeKey === optionSxKey
                    );

                    const mainTreeStateIdx = reportState.treeStates.findIndex(
                      (ts) => ts.treeKey === treeKey
                    );

                    // found
                    if (sxTreeStateIdx !== -1) {
                      setReportState(
                        produce(reportState, (draft) => {
                          draft?.treeStates.splice(sxTreeStateIdx, 1);

                          const nKeys =
                            draft.treeStates?.[mainTreeStateIdx]?.answerMap[questionKey]
                              ?.answerKeys || [];
                          if (
                            draft?.treeStates[mainTreeStateIdx].answerMap[questionKey]?.answerKeys
                          ) {
                            // @ts-ignore
                            draft.treeStates[mainTreeStateIdx].answerMap[questionKey].answerKeys =
                              nKeys.filter((k) => k !== optionSxKey);
                          }
                        })
                      );
                    }
                  }}
                >
                  <BiTrash size={22} className='cursor-pointer' />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(QsSxCollector);
