import produce from 'immer';
import { useEffect } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { useData } from '../../report/use-data';
import { PaReportState, getTopLevelQsState } from '../hooks/use-report';
import { UpdateAnswerHandler, UpdateAnswerValueHandler } from '../pa-prop-types';
import { Key, ModalSxStateType, UIMetadataT } from '../patient-types';

export const ModalSxState = atomFamily<ModalSxStateType | null, Key | undefined>({
  key: 'ModalSxState',
  default: null,
});

export function useSxModalState({
  sxKey,
  initState,
}: {
  sxKey?: Key;
  initState?: ModalSxStateType | null;
}) {
  const [
    {
      dataState: { survey },
    },
    { getQuestionByKey, getTreeByKey },
  ] = useData();
  const [modalSxState, setModalSxState] = useRecoilState(ModalSxState(sxKey));
  const [reportState, setReportState] = useRecoilState(PaReportState);

  const tree = getTreeByKey(sxKey || '');

  /* console.log('tree', tree); */
  /* console.log('sxKey', sxKey); */
  /* console.log('tree?.treeKey', tree?.treeKey); */
  /* console.log('>>> initState', initState); */
  /* console.log('modalSxState', modalSxState); */

  const topLevelQsMap = getTopLevelQsState({
    reportState,
    survey,
  });

  useEffect(() => {
    if (sxKey && tree?.treeKey) {
      const nAnswerMap = modalSxState?.answerMap || {};
      const nInitState = initState || {
        answerMap: { ...nAnswerMap, ...topLevelQsMap },
        treeKey: sxKey,
        tree,
      };

      setModalSxState(nInitState);
    } else if (initState) {
      setModalSxState({
        ...initState,
        answerMap: {
          ...initState.answerMap,
          ...topLevelQsMap,
        },
      });
    }
  }, [tree?.treeKey]);

  function onUiMetadataChange({
    uiMetadata,
    questionKey,
  }: {
    uiMetadata: UIMetadataT;
    questionKey: Key;
  }) {
    // answer states
    setModalSxState((st) => {
      return produce(st, (draft) => {
        if (draft?.answerMap[questionKey] != null) {
          // @ts-ignore
          draft.answerMap[questionKey].uiMetadata = uiMetadata;
        }
      });
    });
  }

  const updateAnswerValue: UpdateAnswerValueHandler = ({ questionKey, answerValue }) => {
    // answer states
    const nState = produce(modalSxState, (draft) => {
      if (!draft) return;
      const { answerMap } = draft;
      answerMap[questionKey] = answerValue;
    });

    // answer states
    setModalSxState(nState);
  };

  const updateAnswer: UpdateAnswerHandler = (params) => {
    const { questionKey } = params;

    const nState = produce(modalSxState, (draft) => {
      if (!draft) return;
      const { answerMap } = draft;

      if (answerMap[questionKey] == null) {
        answerMap[questionKey] = {
          // delete answerMap[questionKey];
          answerKeys: [],
          answerValues: [],
        };
      }

      answerMap[questionKey] = {
        ...answerMap[questionKey],
        ...params,
      };
    });

    // answer states
    setModalSxState(nState);

    // update global answer state
    const qs = getQuestionByKey(questionKey);
    const isGlobal = !!qs?.global;

    setReportState((st) =>
      produce(st, (draft) => {
        const { answerMap } = nState || {};

        if (isGlobal && answerMap) {
          draft.globalAnswerMap = {
            ...draft.globalAnswerMap,
            [questionKey]: answerMap?.[questionKey],
          };
        }
      })
    );
  };

  return [
    { modalSxState: modalSxState || initState, sxKey },
    { onUiMetadataChange, updateAnswerValue, updateAnswer, setModalSxState },
  ] as const;
}
