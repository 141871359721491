import { CSSProperties, SVGAttributes } from 'react';
import { Keys } from '../../../../patient-types';

const ButtonRadius = 12;

const BodySelectButton = ({
  selectedKeys,
  d,
  selectStyle,
  ...props
}: SVGAttributes<SVGCircleElement> & {
  selectStyle?: CSSProperties;
  selectedKeys?: Keys;
  d: string;
}) => {
  const { id } = props;

  const selected = id && selectedKeys?.includes(id);

  const fill1 = !selected ? '#3455FC' : '#fff';
  const fill2 = selected ? '#3455FC' : '#fff';

  const nums = d.match(/-?\d+(\.\d+)?/g) || [];

  const num2 = nums[1];
  const num4 = nums[3];

  const transform = !selected && num2 && num4 ? '' : `rotate(45 ${num4} ${num2})`;

  return (
    <g className='cursor-pointer'>
      <circle {...props} r={ButtonRadius} fill={fill1} strokeWidth={1.5} stroke='#3455FC'></circle>
      <circle {...props} r={ButtonRadius + 10} fill={'transparent'} strokeWidth={1.5} />
      <path
        id={id}
        stroke={fill2}
        transform={transform}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        style={selected ? selectStyle : {}}
        d={d}
      />
    </g>
  );
};

export default BodySelectButton;
